<div class="row mrg-btm-10 mrg-top-25">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-money"></i> Agregar creditos</h4></div>
</div>
<div class="row">
  <div class="col-md-12 col-sm-12">
    <form [formGroup]="fCredits">
      <div class="form-group mrg-right-10">
        <label>Credito actual: {{ this.data }}</label>
      </div>
      <div class="form-group mrg-right-10">
        <label>Nuevo credito</label>
        <input formControlName="fNewCredit" type="text" class="form-control" placeholder="Creditos" >
      </div>
      <div class="form-group mrg-right-10">
        <label>Fecha validez inicio:</label>
        <input formControlName="fInitDate"
               type="text" class="form-control "
               ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" placeholder="Fecha inicio" >
      </div>
      <div class="form-group mrg-right-10">
        <label>Fecha validez final:</label>
        <input formControlName="fEndDate"
               type="text" class="form-control"
               ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" placeholder="Fecha fin" >
      </div>
    </form>
  </div>
</div>
<div *ngIf="(showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-success animated zoomIn text-center">Se agrego el credito</div>
  </div>
</div>
<div *ngIf="(!showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="onAddCredit();" class="btn btn-intrabase btn-rounded btn-inverse">Agregar</label></li>
      <li class="list-inline-item"><label data-dismiss="modal" class="btn btn-danger btn-rounded btn-inverse">Cerrar</label></li>
    </ul>
  </div>
</div>
