<div class="authentication">
  <div class="sign-in">
    <div class="row no-mrg-horizon">
      <div class="col-md-8 no-pdd-horizon d-none d-md-block">
        <div class="full-height bg" style="background-image: url('assets/images/others/img-29.jpg')">
          <div class="img-caption">
            <h1 class="caption-title">Bienvenido a Intrabase</h1>
            <p class="caption-text"></p>
          </div>
        </div>
      </div>
      <div class="col-md-4 no-pdd-horizon">
        <div class="full-height bg-white height-100">
          <div class="vertical-align full-height pdd-horizon-70">
            <div class="table-cell">
              <div class="pdd-horizon-15">
                <h2>Inicio</h2>
                <p class="mrg-btm-15 font-size-13">Ingrese su usuario y contraseña</p>
                <form [formGroup]="form" (ngSubmit)="login()">
                  <div *ngIf="form.errors?.invalidLogin" class="alert alert-danger">Error con el usuario o Password</div>
                  <div *ngIf="form.errors?.blocked" class="alert alert-danger">Su usuario esta deshabilitado</div>
                  <div class="form-group">
                    <input
                      formControlName="username"
                      id="username"
                      type="email" class="form-control" placeholder="Usuario">
                    <div *ngIf="username.touched && username.invalid" class="alert alert-danger">
                      <div *ngIf="username.errors.required">Debe ingresar su usuario</div>
                      <div *ngIf="username.errors.minlength">Tiene que tenes minimo 3 letras</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      formControlName="password"
                      id="password"
                      type="password" class="form-control" placeholder="Contraseña">
                    <div *ngIf="password.touched && password.invalid" class="alert alert-danger">Debe ingresar su contraseña</div>
                  </div>
                  <div *ngIf="(loading==true)" class="row"><div class="col-sm-12 text-center"><img src="../../assets/images/loading_small.gif"> Ingresando</div></div>
                  <button *ngIf="(loading==false)" class="btn btn-intrabase">Ingresar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
