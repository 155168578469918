import { Component, Input, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {LocalStorage} from 'ngx-webstorage';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-usuarios-credits',
  templateUrl: './usuarios-credits.component.html'
})
export class UsuariosCreditsComponent implements OnInit {

  @LocalStorage('session') localSess: any;
  @Input() data: any;
  @Input() id: number;
  @Input() idx: number;

  users: any;
  showResult: boolean;

  constructor(private sync: DataSyncComponentsService, private serviceUsers: UsersService) { }

  // Init Controls
  fCredits = new FormGroup( {
    fNewCredit: new FormControl(),
    fInitDate: new FormControl(),
    fEndDate: new FormControl()
  });

  onAddCredit() {
    let resultCredits: any;
    const data = {user_id: this.id,
                  valid_from: this.getDateFromDatepicker(this.initDate.value) ,
                  valid_to:  this.getDateFromDatepicker(this.endDate.value),
                  credits: this.newcredits.value
                };
    this.serviceUsers.addCreditsToUser(data).subscribe(
      result => {
        resultCredits = result;
        this.users[this.idx].credits = resultCredits.credits;
        this.showResult = true;
        this.cleanForm();
      }
    );
  }

  cleanForm () {
    this.newcredits.reset();
    this.initDate.reset();
    this.endDate.reset();
    setTimeout(() => {
      this.showResult = false;
    }, 3500);
  }

  get newcredits() {
    return this.fCredits.get('fNewCredit');
  }
  get initDate() {
    return this.fCredits.get('fInitDate');
  }
  get endDate() {
    return this.fCredits.get('fEndDate');
  }

  private getDateFromDatepicker(obj) {
    return obj.year + '-' + obj.month + '-' + obj.day;
  }

  ngOnInit() {
    this.sync.currentUsers.subscribe( users => this.users = users);
  }

}
