<div class="row">
  <div class="col-md-12">
    <div class="card padding-20">
      <h4 class="card-title mrg-btm-20"><i class="ti-cloud-down"></i> Mis Descargas</h4>
      <div *ngIf="(loading==true)" class="row"><div class="col-sm-12 text-center"> <img src="../../../assets/images/loading_big.gif" /><br><h2>Cargando Archivos</h2></div></div>
      <div *ngIf="(loading==false)">
        <ul *ngFor="let file of allFiles; index as i" class="list-members" >
          <li class="text-center padding-30">
            <i class="ti-file font-size-80"></i>
            <br>
            <label>Total proyeccion: {{file.results}}</label>
            <br>
            <label *ngIf="(file.enabled==true)"
               (click)="downloadArchivedFile(file.id, file.results)"
               class="btn btn-primary btn-inverse btn-icon btn-rounded"
               placement="top" ngbTooltip="Descargar archivo">
              <i class="ti-download"></i>
            </label>
            <app-map [idProjection]="file.id" [beginCoords]="file.bounds" [originCall]="'downloads'" [custom]="'-1'"></app-map>
            <br>
            <a href="javascript:void(0);"
               data-toggle="modal" data-target="#moreInfo"
               (click)="moreInfo(i)"
               class="download-list-info"><i class="ti-plus"></i><span> Info</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- More info -->
<div class="modal fade" id="moreInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-title">
        <div class="row"><div class="col-md-12 text-center mrg-btm-5 mrg-top-20"><h3>Detalle del contenido</h3></div></div>
      </div>
      <!-- file type -->
      <div class="row">
        <div class="col-md-12 text-center">
          <ul class="list-members">
            <li><span class="label label-danger">Tipo de Archivo: {{ moreInfoData.filetype | filetypeName }}</span></li>
          </ul>
        </div>
      </div>
      <!-- Locations -->
      <div class="row">
        <div class="col-md-12 text-center">
          <ul class="list-members">
            <li *ngFor="let option of moreInfoData.locations; index as i">
              <span class="label label-info">{{ option | downloadLocations }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- Filters -->
      <div class="row">
        <div class="col-md-12 text-center">
          <ul class="list-members">
            <li *ngFor="let filter of moreInfoData.filters; index as i">
              <span class="label label-primary">{{ filter.value }} </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer mrg-top-15" >
        <a href="javascript:void(0);" class="btn btn-default" data-dismiss="modal">Cerrar</a>
      </div>
    </div>
  </div>
</div>
<!-- -->
