import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import {Endpoints} from '../shared/common/endpoints.enum';

@Injectable()
export class LoginService extends DataService {

  login: any;
  countries: any;

  constructor(http: HttpClient) {
    super('', http);
  }

  // try login
  getLogin(auth) {
    return this.login = this.sendGETNoAuth( Endpoints.root + Endpoints.login, auth );
  }

  // get countries
  getCountriesForUser() {
    const url = Endpoints.root + Endpoints.getCountries;
    return this.countries = this.sendGET(url);
  }

}
