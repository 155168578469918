<div class="app" [ngClass]="
    {
        'is-collapsed': app.layout.isMenuCollapsed,
        'is-opened': app.layout.isMenuOpened
    }">
  <div class="layout" [ngClass]="headerSelected">
    <!-- Side Bar -->
    <div [ngClass]="sidenavSelected">
      <div class="side-nav" sideBar>
        <div class="side-nav-inner">
          <div class="side-nav-logo">
            <a href="" [routerLink]="['/dashboard']">
              <div class="logo logo-white" style="background-image: url('../../assets/images/logo/intrabase.png')"></div>
            </a>
            <div class="mobile-toggle side-nav-toggle">
              <a href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
          <ul class="side-nav-menu scrollable">
            <li class="nav-item" >
              <a class="mrg-top-30"
                 routerLinkActive="active"
                 [routerLink]="['/dashboard', { outlets: { content: ['querymaster'] } }]">
                                <span class="icon-holder">
                                    <i class="ti-bar-chart"></i>
                                </span>
                <span class="title">Proyecciones</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="mrg-top-5"
                 routerLinkActive="active"
                 [routerLink]="['/dashboard', { outlets: { content: ['downloadsmaster'] } }]">
                                <span class="icon-holder">
                                    <i class="ti-download"></i>
                                </span>
                <span class="title">Descargas</span>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="mrg-top-5"
                 routerLinkActive="active"
                 [routerLink]="['/dashboard', { outlets: { content: ['custommaster'] } }]">
                                <span class="icon-holder">
                                    <i class="ti-filter"></i>
                                </span>
                <span class="title">Filtros Custom</span>
              </a>
            </li>-->
            <li *ngIf="(localSess.roles=='ROLE_ADMIN')" class="nav-item">
              <a class="mrg-top-5"
                 routerLinkActive="active"
                 [routerLink]="[{ outlets: { content: ['users'] } }]">
                                <span class="icon-holder">
                                    <i class="ti-settings"></i>
                                </span>
                <span class="title">Usuarios</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Side Bar -->
    <!-- Page Container -->
    <div class="page-container">
      <!-- User Data -->
      <div class="header navbar">
        <div class="header-container">
          <ul class="nav-left">
            <li>
              <a href="javascript:void(0);"
                 (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                <i class="ti-view-grid"></i>
              </a>
            </li>
          </ul>
          <ul class="nav-right">
            <li class="user-profile dropdown">
              <a href="" class="dropdown-toggle" data-toggle="dropdown">
                <i class="ti-world font-size-20 pdd-right-15"></i>
                <div class="user-info">
                  <span class="name pdd-right-5">{{ localSess.default_country.name }}</span>
                  <i class="ti-angle-down font-size-10"></i>
                </div>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="" data-toggle="modal" data-target="#modal-sm">
                    <i class="ti-pencil pdd-right-10"></i>
                    <span>Cambiar</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="user-profile dropdown">
              <a href="" class="dropdown-toggle" data-toggle="dropdown">
                <i class="ti-user font-size-20 pdd-right-15"></i>
                <div class="user-info">
                  <span class="name pdd-right-5">{{ localSess.username }}</span>
                  <i class="ti-angle-down font-size-10"></i>
                </div>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="">
                    <i class="ti-money pdd-right-10"></i>
                    <span>Creditos: {{ localSess.credits }}</span>
                  </a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a [routerLink]="['/logout']" href="">
                    <i class="ti-power-off pdd-right-10"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <!-- End Page Container -->

      <!-- Content Wrapper START -->
      <div class="main-content" style="padding-top: 75px;">
            <router-outlet name="content"></router-outlet>
      </div>
      <!-- Content Wrapper END -->

      <!-- Modal for countries -->
      <div class="modal fade" id="modal-sm">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <form [formGroup]="fSelect" >
                <h3>Seleccione el pais</h3>
                <ng-select placeholder="Seleccione..."
                           [clearable]="false"
                           (change)="onSelect($event)"
                           formControlName="countries">
                  <ng-option *ngFor="let option of localSess.countries index as e" [value]="e"
                             >{{option.name}}</ng-option>
                </ng-select>
              </form>
            </div>
            <button data-dismiss="modal" class="btn btn-intrabase btn-block no-mrg no-border pdd-vertical-15 ng-scope">
              <span class="text-uppercase">Cerrar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
