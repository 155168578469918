<!-- Buttons -->
<label *ngIf="(originCall == 'downloads')"
       class="btn btn-success btn-inverse btn-icon btn-rounded"

       (click)="openMap(content)" >
  <i placement="top" ngbTooltip="Ver en mapa" class="ti-location-pin"></i>
</label>
<label *ngIf="(originCall == 'projection')"
       (click)="openMap(content)"
       class="btn btn-block btn-inverse btn-primary btn-rounded text-uppercase">ver en mapa
</label>
<label *ngIf="(originCall == 'projectionCustom')"
       (click)="openMap(content)"
       class="btn btn-block btn-inverse btn-warning btn-rounded text-uppercase">PROYECTAR CUSTOM
</label>
<!-- Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Mapa de calor</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <div id="map"
             leaflet
             [leafletOptions]="options"
             (leafletClick)="onClickPoint($event)"
             (leafletMapReady)="onReadyMap($event)">
            <div *ngIf="showCustomLayer" [leafletLayer]="customLayer"></div>
            <div *ngIf="showDrawer"
                leafletDraw
                [leafletDrawOptions]="drawOptions"
                 (leafletDrawStart)="checkLayer($event)"
                 (leafletDrawReady)="onReadyDraw($event)"
                 (leafletDrawEditStop)="getRectangleEditedData()"
                (leafletDrawCreated)="getRectangleData($event)"></div>
            <div *ngIf="showDisabled" class="disabled-map">
              <div>Cargando...</div>
            </div>
        </div>
      </div>
      <div class="col-md-2 col-sm-12">
        <div class="row"><div class="col-sm-12 col-md-12 text-center">
          <h5>Herramientas de Seleccion</h5>
        </div></div>
        <div class="row">
          <div class="col-sm-12 col-md-12 text-center">
            <div class="btn-group">
              <label class="btn btn-default" (click)="onClickShowDrawer()"><span>Region</span></label>
              <label class="btn btn-default" (click)="onClickShowPointer()"><span>Punto</span></label>
            </div>
          </div>
        </div>
        <div *ngIf="(showDrawer)" class="row">
          <div class="col-sm-12 col-md-12 text-center text-light">
            <p><i><b>Region:&nbsp;</b>Utilice la herramienta 'cuadrado' del mapa para seleccionar una region</i></p>
          </div>
        </div>
        <div *ngIf="(toolPointerActive)" class="row">
          <div class="col-sm-12 col-md-12 text-center text-light">
            <p><i><b>Punto:&nbsp;</b>Haga clic sobre el mapa</i></p>
          </div>
        </div>
        <!-- Results for point tool -->
        <div *ngIf="showPointResult" class="row" [@fadeInOutResult]>
          <div class="col-sm-12 col-md-12 text-center" style="margin-top: 220px;">
            <ul class="list-map-info">
              <li class="list-map-info-header">Descargar seleccion</li>
              <li *ngIf="customVariable">
                <form [formGroup]="fTypeFileCustom">
                  <div class="col-md-12 text-center">
                    <div class="radio radio-inline radio-primary mrg-left-10">
                      <input type="radio" formControlName="fTypeFieldCustom" id="coords" value="COORDENATES" [checked]="true" checked>
                      <label for="coords">Coordenadas</label>
                    </div>
                    <div class="radio radio-inline radio-primary">
                      <input type="radio" formControlName="fTypeFieldCustom" value="ADDRESSES" id="adress">
                      <label for="adress">Direcciones</label>
                    </div>
                  </div>
                </form>
              </li>
              <li>
                <label class="btn btn-intrabase" (click)="onClickDownloadPoint()">Descargar</label>
              </li>
            </ul>
          </div>
        </div>
        <!-- Results for region tool -->
        <div *ngIf="showRegionResult" class="row" [@fadeInOutResult]>
          <div class="col-sm-12 col-md-12 text-center" style="margin-top: 130px;">
            <ul class="list-map-info">
              <li class="list-map-info-point">
                total hogares: <span>{{ retTotalArea.section_data.total_hogares }}</span>
              </li>
              <li class="list-map-info-point">
                total ubicaciones: <span>{{ retTotalArea.size }}</span>
              </li>
              <li class="list-map-info-header">Descargar seleccion</li>
              <li *ngIf="customVariable">
                <form [formGroup]="fTypeFileCustom">
                  <div class="col-md-12 text-center">
                    <div class="radio radio-inline radio-primary mrg-left-10">
                      <input type="radio" formControlName="fTypeFieldCustom" id="coords" value="COORDENATES" [checked]="true" checked>
                      <label for="coords">Coordenadas</label>
                    </div>
                    <div class="radio radio-inline radio-primary">
                      <input type="radio" formControlName="fTypeFieldCustom" value="ADDRESSES" id="adress">
                      <label for="adress">Direcciones</label>
                    </div>
                  </div>
                </form>
              </li>
              <li>
                <label class="btn btn-intrabase" (click)="onClickDownloadArea()">Descargar</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
