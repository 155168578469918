<!-- Row -->
<div class="row">
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-1"><a href="javascript:void(0);"
                               (click)="onDeleteFilter(indexFilter)"
                               class="btn btn-rounded btn-icon btn-danger btn-inverse"><i class="ti-close"></i></a></div>
      <div class="col-md-11 text-center">{{ filterData.name | CategoryName }}</div>
    </div>
    <div *ngFor="let value of filterData.items"  class="checkbox checkbox-inline checkbox-primary mrg-top-5">
      <label
        class="btn btn-default btn-rounded btn-inverse"
        (click)="selOptionFilter(filterData.id, filterData.name, value.obj)" >
        <i class="ti-plus pdd-right-5"></i>
        <span>{{ value.obj }}</span>
      </label>
    </div>
  </div>
  <div class="col-md-2 text-right">
    <ngx-charts-pie-chart
      [view]="[120,120]"
      [results]="chartDataLocal"
      [scheme]="color"
    >
      <ng-template #tooltipTemplate let-model="model">
        <h2><p class="text-bold text-white">{{model.value }}%</p></h2>
        <h5><p class="text-white">{{model.name}}</p></h5>
      </ng-template>
    </ngx-charts-pie-chart>
  </div>
</div>

