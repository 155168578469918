import {Component, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup} from '@angular/forms';

import { LocationsService } from '../../services/locations.service';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';

@Component({
  selector: 'app-selectcoord',
  templateUrl: './selectcoord.component.html',
})
export class SelectcoordComponent implements OnInit {
  // for selects
  countries: any;
  selectsLocations: any;
  dataChart: any;
  // for selects options
  selectOptionsL0: any = Array();
  selectOptionsL1: any = Array();
  selectOptionsL2: any = Array();
  selectOptionsL3: any = Array();
  selectOptionsL4: any = Array();
  selectedLocation: any = Array({L1: null, L2: null, L3: null, L4: null, L5: null});
  totalSelects = 6;
  // For data sync
  filters: any; locations: any; queryMaster: any; actualQuery: any; categoriesCombo: any; flagCountry: any;

  @LocalStorage('session') localSess: any;

  enabledAddLocation: boolean;
  loading: boolean;

  constructor(private service: LocationsService,
              private sync: DataSyncComponentsService) {}

  // Init form control
  fSelects = new FormGroup({
      L1: new FormControl(),
      L2: new FormControl(),
      L3: new FormControl(),
      L4: new FormControl(),
      L5: new FormControl()
  });

  // Methods to control selection
  onSelectItem (item, next) {
    if (item !== undefined) {
      if (next !== 5) {
        // Load data for next select
        this.getNextLocation (item, next);
      }
      // Add selection
      this.setSelection(item, next);
    }
  }

  onClearItems (item) {
    this.resetSelects(item, true);
  }

  // Private Methods
  // Dinamic load selects
  private getNextLocation (selected, nextLocation) {
    let dataQuery: any;
    this.loading = true;
    const arrSelected = selected.split('#');
    const key = arrSelected[0];
    if (nextLocation === 1) {
      dataQuery = { 'country_id': this.localSess.default_country.id };
    } else {
      dataQuery = '{ "country_id": "' + this.localSess.default_country.id + '", ' +
                  '"location_filters": [{ "' + key.toLocaleLowerCase() + '":  "'
        + arrSelected[1] + '" }]}';
    }
    this.service.getSelectsLocation(dataQuery).subscribe(
      locations => {
        this.loading = false;
        this.selectsLocations = locations;
        if (nextLocation === 1) { this.setDataChart('COUNTRY'); }
        this.resetSelects(nextLocation);
        this.setSelectsLocations(this.selectsLocations.availableFilters[0], nextLocation);
      }
    );
  }
  // Add data for charts
  private setDataChart(label) {
    this.dataChart.push({ 'total': this.selectsLocations.total, 'filters': this.selectsLocations.availableFilters, 'label': label });
    this.sync.changeChartData(this.dataChart);
  }
  // _
  private resetSelects (obj, emptyMe = false) {
    let i: number;
    if (emptyMe) {
      this.fSelects.get('L' + (obj - 1) ).reset();
      this.selectedLocation[0]['L' + (obj - 1) ] = null;
    }
    for (i = obj; i < this.totalSelects; i++) {
      if (this.selectedLocation[0]['L' + (i + 1) ] != null ) {
        this.fSelects.get('L' + (i + 1) ).reset();
        this.selectedLocation[0]['L' + (i + 1) ] = null;
      }
      switch (i) {
        case 0:
          this.selectOptionsL0 = [];
          break;
        case 1:
          this.selectOptionsL1 = [];
          break;
        case 2:
          this.selectOptionsL2 = [];
          break;
        case 3:
          this.selectOptionsL3 = [];
          break;
        case 4:
          this.selectOptionsL4 = [];
          break;
      }
    }
  }
  // _
  private setSelectsLocations (Options: any, next: number) {
    const tmpArr: any = Array();
    Options.slices.forEach(
      option => {
        if (option.obj !== '') {
          tmpArr.push({ label: option.obj, value: Options.id });
        }
      }
    );
    switch (next) {
      case 0:
        this.selectOptionsL0 = tmpArr;
        break;
      case 1:
        this.selectOptionsL1 = tmpArr;
        break;
      case 2:
        this.selectOptionsL2 = tmpArr;
        break;
      case 3:
        this.selectOptionsL3 = tmpArr;
        break;
      case 4:
        this.selectOptionsL4 = tmpArr;
        break;
    }
  }
  // Update selected location
  private setSelection (location, pos: number) {
    this.selectedLocation[0]['L' + pos] = location;
  }
  // Change button state
  private toogleAddNewLocation (action = true) {
    this.enabledAddLocation = action;
  }
  // _
  private setAddLocation () {
    const arrLocations = this.actualQuery;
    const newLocation = this.parseNewAddedLocation();
    arrLocations.locations.push(newLocation);
    this.setDataChart(newLocation.label);
    this.sync.changeLocations(arrLocations);
  }
  // _
  private parseNewAddedLocation() {
    let returnValue = '';
    const data = Array();
    let value;
    if (this.selectedLocation[0].L2 !== null ) {
      value = this.selectedLocation[0].L2.split('#')[1];
      returnValue += 'Nivel 1: ' + value + ' ';
      data.push({'L2': value});
    }
    if (this.selectedLocation[0].L3 !== null ) {
      value = this.selectedLocation[0].L3.split('#')[1];
      returnValue += 'Nivel 2: ' +  value + ' ';
      data.push({'L3': value});
    }
    if (this.selectedLocation[0].L4 !== null ) {
      value = this.selectedLocation[0].L4.split('#')[1];
      returnValue += 'Nivel 3: ' + value + ' ';
      data.push({'L4': value});
    }
    if (this.selectedLocation[0].L5 !== null ) {
      value = this.selectedLocation[0].L5.split('#')[1];
      returnValue += 'Nivel 4: ' + value + ' ';
      data.push({'L5': value});
    }
    return {'label': returnValue, 'data': data};
  }

  ngOnInit() {
    this.sync.currentChartData.subscribe( data => this.dataChart = data );
    this.sync.currentChangedCountry.subscribe( flag => {
      if (flag) {
        const tmp1 = this.localSess.default_country.id + '#' + this.localSess.default_country.name;
        this.getNextLocation(tmp1, 1);
      }
    });
    this.sync.currentFilters.subscribe(filters => this.filters = filters );
    this.sync.currentLocations.subscribe( locations => this.locations = locations );
    this.sync.currentQueryMaster.subscribe(query => this.queryMaster = query );
    this.sync.currentActualQuery.subscribe( query => this.actualQuery = query );
    this.sync.currentCategoriesCmb.subscribe( categoriesCombo => this.categoriesCombo = categoriesCombo );
    this.toogleAddNewLocation(false);
    // Init selects
    const tmp = this.localSess.default_country.id + '#' + this.localSess.default_country.name;
    this.getNextLocation(tmp, 1);
    //
  }
}
