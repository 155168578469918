import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DataSyncComponentsService} from '../../services/data-sync-components.service';

@Component({
  selector: 'app-custom-filters',
  templateUrl: './custom-filters.component.html'
})
export class CustomFiltersComponent implements OnInit {

  categoriesCmb: any;
  variableSelected: any;
  filtersCustomSelected: any;
  actualQuery: any;

  constructor(private sync: DataSyncComponentsService) {}

  // Init Controls
  fSelects = new FormGroup( {
    fVariables: new FormControl()
  });

  onAddCustomVariable() {
    this.filtersCustomSelected.unshift(this.variableSelected);
    this.actualQuery.customFilters.push(this.variableSelected);
  }

  onSelectCustom(event) {
    this.variableSelected = this.categoriesCmb.custom.variables[event];
  }

  resetControls() {
    this.clearControls('fVariables');
  }

  clearControls(control) {
    if (this.fSelects.get(control) !== null) {
      this.fSelects.get(control).reset();
    }
  }

  resetAll() {
    this.clearControls('fVariables');
    this.clearControls('fCategory');
  }

  ngOnInit() {
    this.sync.currentChangedCountry.subscribe( flag => {
      if (flag) {
        this.resetAll();
      }
    });
    this.sync.currentCategoriesCmb.subscribe( categoriesCombo => this.categoriesCmb = categoriesCombo );
    this.sync.currentFiltersCustomSelected.subscribe( filtersCustomSelected => this.filtersCustomSelected = filtersCustomSelected );
    this.sync.currentActualQuery.subscribe( actual => this.actualQuery = actual);
  }

}
