import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import {Endpoints} from '../shared/common/endpoints.enum';

@Injectable()
export class LocationsService extends DataService {

  locations: any;
  results: any;
  countries: any;

  constructor(http: HttpClient) {
    super('', http);
  }

  // get countries
  getInitCountries () {
    return this.countries = this.sendGET(Endpoints.root + Endpoints.getCountries);
  }

  // get locations combo
  getSelectsLocation (data) {
    return this.locations = this.sendPOST( Endpoints.root + Endpoints.proyectar, data);
  }

  // get results
  getReultsQuery(data) {
    return this.results = this.sendPOST(Endpoints.root + Endpoints.proyectar, data);
  }
}
