<div class="padding-20">
  <h4 class="card-title no-mrg-btm"><i class="ti-location-pin"></i> Ubicacion</h4>
</div>
<form [formGroup]="fSelects" style="padding-top: 5px; padding-left: 10px; padding-bottom: 15px;">

  <div class="row">
    <!-- L1 -->
    <div class="col-md-12" *ngIf="(selectOptionsL1.length>0)">
      <div class="form-group mrg-right-10">
        <label>Nivel 1</label>
        <ng-select placeholder="Seleccione..."
                   [clearable]="false"
                   (change)="onSelectItem($event,2)"
                   (clear)="onClearItems(2)"
                   formControlName="L2">
          <ng-option *ngFor="let option of selectOptionsL1"
                     [value]="option.value+'#'+option.label" >{{option.label}}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- L2 -->
    <div class="col-md-12" *ngIf="(selectOptionsL2.length>0)">
      <div class="form-group mrg-right-10">
        <label>Nivel 2</label>
        <ng-select placeholder="Seleccione..."
                   [clearable]="false"
                   (change)="onSelectItem($event,3)"
                   (clear)="onClearItems(3)"
                   formControlName="L3">
          <ng-option *ngFor="let option of selectOptionsL2"
                     [value]="option.value+'#'+option.label" >{{option.label}}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- L3 -->
    <div class="col-md-12" *ngIf="(selectOptionsL3.length>0)">
      <div class="form-group mrg-right-10">
        <label>Nivel 3</label>
        <ng-select placeholder="Seleccione..."
                   [clearable]="false"
                   (change)="onSelectItem($event,4)"
                   (clear)="onClearItems(4)"
                   formControlName="L4">
          <ng-option *ngFor="let option of selectOptionsL3"
                     [value]="option.value+'#'+option.label" >{{option.label}}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- L4 -->
    <div class="col-md-12" *ngIf="(selectOptionsL4.length>0)">
      <div class="form-group mrg-right-10">
        <label>Nivel 4</label>
        <ng-select placeholder="Seleccione..."
                   [clearable]="false"
                   (change)="onSelectItem($event,5)"
                   (clear)="onClearItems(5)"
                   formControlName="L5">
          <ng-option *ngFor="let option of selectOptionsL4"
                     [value]="option.value+'#'+option.label" >{{option.label}}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row mrg-top-10">
    <div class="col-sm-12 col-md-12">
      <ul class="list-unstyled text-right list-inline pdd-vertical-5">
        <li class="list-inline-item"><label
                                        class="btn btn-intrabase btn-rounded"
                                        *ngIf="(loading==false)"
                                        (click)="setAddLocation()">Agregar</label></li>
        <li class="list-inline-item"><label
                                        *ngIf="(loading==false)"
                                        data-dismiss="modal" data- class="btn btn-danger btn-rounded">Cerrar</label></li>
      </ul>
    </div>
  </div>
  <div *ngIf="(loading==true)" class="row"><div class="col-sm-12 text-center"><img src="../../../assets/images/loading_small.gif"> Cargando datos...</div></div>
</form>
