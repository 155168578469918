import {Component, Input, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tileLayer, Map, Control, DomUtil, popup} from 'leaflet';
import { Endpoints } from '../shared/common/endpoints.enum';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MapService} from './services/map.service';
import {formatDate} from '@angular/common';
import {LocalStorage} from 'ngx-webstorage';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  animations: [
    trigger('fadeInOutResult', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(400)),
    ])
  ]
})
export class MapComponent implements OnInit {

  @Input() idProjection: string;
  @Input() beginCoords: any;
  @Input() originCall: string;
  @Input() custom: any;

  localMap: Map;
  showCustomLayer: boolean;
  showDrawer: boolean;
  showDisabled: boolean;
  showRegionResult: boolean;
  showPointResult: boolean;
  customLayer: any;
  options: any;
  drawOptions: any;
  actualLayer: any;
  actualDrawer: any;
  toolPointerActive: boolean;
  retTotalArea: any;
  actualPointData: any;
  customVariable: boolean;

  @LocalStorage('session') localSess: any;

  constructor(private modalService: NgbModal, private service: MapService) {
    this.showCustomLayer = false;
    this.showDrawer = false;
    this.showDisabled = false;
    this.showRegionResult = false;
    this.showPointResult = false;
    this.toolPointerActive = false;
    this.retTotalArea = 0;

    this.drawOptions = {
      position: 'topright',
      draw: {
        polygon: false,
        marker: false,
        polyline: false,
        circle: false,
        circlemarker: false
      }
    };

    this.options = {
      layers: [
        tileLayer(Endpoints.getMap + Endpoints.tokenMap, {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        })
      ],
      zoom: 5
    };

  }

  // Init Controls
  fTypeFileCustom = new FormGroup({
    fTypeFieldCustom: new FormControl()
  });

  ngOnInit() {
  }

  onReadyMap(map: Map) {
    this.localMap = map;
    if (this.beginCoords === '-1' && this.custom === '-1') {
      this.localMap.fitBounds([
        [43.62551401692959, 5.752887725830079],
        [35.15289833180084, -9.627971649169924]
      ]);
      //
      const legend = new Control({position: 'bottomright'});
      legend.onAdd = function () {
        const div = DomUtil.create('div', 'mapInfo mapLegend');
        const labels = ['<strong>Referencia</strong>'],
          categories = ['Muy alta potencialidad',
            'Alta potencialidad',
            'Media potencialidad',
            'Baja potencialidad',
            'Muy baja potencialidad'];
        const colors = ['#DF2935', '#6EEB83', '#1BE7FF', '#F4F149', '#FFAE03'];
        for (let i = 0; i < categories.length; i++) {
          div.innerHTML += '<i style="background:' + colors[i] + '"></i> ' + categories[i] + '<br>';
        }
        return div;
      };
      legend.addTo(map);
    }
    // For custom
    if (this.customVariable) {
      // fit bounds
      const countryBounds = this.localSess.default_country.bounds.split(',');
      this.localMap.fitBounds([
        [parseInt(countryBounds[3], 10), parseInt(countryBounds[0], 10)],
        [parseInt(countryBounds[1], 10), parseInt(countryBounds[2], 10)]
      ]);
      // add legend
      const customLegend = this.createCustomLegend();
      customLegend.addTo(map);
    } else {
      // fit init map
      this.localMap.fitBounds([
        [this.beginCoords.northEast.lat, this.beginCoords.northEast.lon],
        [this.beginCoords.southWest.lat, this.beginCoords.southWest.lon]
      ]);
    }
  }

  onReadyDraw(drawControl: Control.Draw) {
    this.actualDrawer = drawControl;
  }

  checkLayer(event) {
    this.deleteLayer();
  }

  onClickShowDrawer() {
    this.hideResultPanels();
    if (this.showDrawer === false) {
      this.showDrawer = true;
      this.toolPointerActive = false;
      this.localMap.closePopup();
    } else {
      this.deleteLayer();
      this.showDrawer = false;
    }
  }

  onClickShowPointer() {
    // check if an layer exists
    this.deleteLayer();
    this.hideResultPanels();
    this.showDrawer = false;
    this.toolPointerActive = true;
  }

  onClickDownloadArea() {
    //
    if (this.customVariable) {
      this.showDisabled = true;
      const coords = this.getDataAreaService(this.actualLayer._latlngs[0], this.fileType.value);
      const country   = this.localSess.default_country.id;
      const variable  = this.custom.id;
      // query service
      this.service.getAreaFileCustom(country, variable, coords).subscribe( areaFileCustom => {
        this.downloadFiles(areaFileCustom, this.setFileName('areaCustom'));
        this.showDisabled = false;
      });

    } else {
      const coords = this.getDataAreaService(this.actualLayer._latlngs[0]);
      this.showDisabled = true;
      // query service
      this.service.getAreaFile(this.idProjection, coords).subscribe( areaFile => {
        this.downloadFiles(areaFile, this.setFileName('area'));
        this.showDisabled = false;
      });
    }
    //
  }

  onClickDownloadPoint() {
    this.showDisabled = true;

    if (this.customVariable) {
      const customData = this.actualPointData;
      customData['format'] = this.fileType.value;
      const country   = this.localSess.default_country.id;
      const variable  = this.custom.id;
      // query service
      this.service.getPointFileCustom(country, variable, customData).subscribe( pointFileCustom => {
        this.downloadFiles(pointFileCustom, this.setFileName('pointCustom'));
        this.showDisabled = false;
      });
    } else {
      // query service
      this.service.getPointFile(this.idProjection, this.actualPointData).subscribe( pointFile => {
        this.downloadFiles(pointFile, this.setFileName('point'));
        this.showDisabled = false;
      });
    }
  }

  getRectangleEditedData() {
    const dataNewRectangle = this.getDataAreaService(this.actualLayer._latlngs[0]);
    this.getDataArea(dataNewRectangle);
  }

  getRectangleData(event) {
    // save actual layer
    this.actualLayer = event.layer;
    //
    const dataRectangle = this.getDataAreaService(event.layer._latlngs[0]);
    this.getDataArea(dataRectangle);
  }

  onClickPoint(event) {
    if (this.toolPointerActive === true) {
      this.showDisabled = true;
      this.hideResultPanels();
      const coords = event.latlng;
      // get data for service
      const latlngStr = '(' + coords.lat.toFixed(3) + ',' + coords.lng.toFixed(3) + ')';
      const bbox = this.localMap.getBounds().getSouthWest().lng + ',' +
        this.localMap.getBounds().getSouthWest().lat + ',' +
        this.localMap.getBounds().getNorthEast().lng + ',' +
        this.localMap.getBounds().getNorthEast().lat;
      const mWidth = this.localMap.getSize().x.toFixed();
      const mHeight = this.localMap.getSize().y.toFixed();
      const mX = this.localMap.layerPointToContainerPoint(event.layerPoint).x.toFixed();
      const mY = this.localMap.layerPointToContainerPoint(event.layerPoint).y.toFixed();
      //
      this.actualPointData = this.getDataPointService(bbox, mHeight, mWidth, mX, mY);
      //
      let data;
      if (this.customVariable) {
        const country   = this.localSess.default_country.id;
        const variable  = this.custom.id;
        this.service.getPointDataCustom(country, variable, this.actualPointData).subscribe(pointData => {
          this.showDisabled = false;
          const popupTotal = popup();
          const dataSize: any = pointData;
          if (dataSize.size == null) {
            data = 'Sin datos';
          } else {
            data = dataSize.size;
          }
          popupTotal.setLatLng(coords);
          popupTotal.setContent('<b>Total</b><br><h3>' + data + '</h3>');
          this.localMap.openPopup(popupTotal);
          this.showPointResult = true;
        });
      } else {
        this.service.getPointData(this.idProjection, this.actualPointData).subscribe(pointData => {
          this.showDisabled = false;
          const popupTotal = popup();
          const dataSize: any = pointData;
          if (dataSize.size == null) {
            data = 'Sin datos';
          } else {
            data = { 'locations': dataSize.size, 'homes': dataSize.section_data.total_hogares};
          }
          const labelData: string = this.getDataPointLabel(data);
          popupTotal.setLatLng(coords);
          popupTotal.setContent(labelData);
          this.localMap.openPopup(popupTotal);
          this.showPointResult = true;
        });
      }
    }
  }

  openMap(content) {
    // url map
    this.customVariable = false;
    let urlMap;
    if (this.custom !== '-1') {
      const newURL = this.getCustomUrlMap(Endpoints.getMapLayerCustom);
      urlMap = Endpoints.root + newURL;
      this.customVariable = true;
    } else {
      urlMap = Endpoints.root + Endpoints.getMapLayer + this.idProjection;
    }
    // add custom layer
    this.customLayer = tileLayer.wms(urlMap, {attribution: '&copy; Intrabase'});
    this.showCustomLayer = true;
    //
    this.modalService.open(content, {windowClass: 'mapFullScreen'}).result.then((result) => {
    }, (reason) => {
      // Remove instace of map
      this.deleteLayer();
      this.localMap.remove();
      this.showRegionResult = false;
      this.showPointResult = false;
      this.showDrawer = false;
    });
  }

  private getDataPointLabel(obj) {
    let retData: string;
    if (obj.locations) {
      if (obj.homes) {
        retData = '<b>Total Hogares: <b><h4>' + obj.homes + '</h4><br><b>Total Ubicaciones: <b><h4>' + obj.locations + '</h4>';
      } else {
        retData = '<b>Total Ubicaciones: <b><h4>' + obj.locations + '</h4>';
      }
    } else {
      retData = '<b>Sin Datos</b>';
    }
    return retData;
  }

  private getDataAreaService(coords, type?) {
    const retData = {
      'top_left': {
        'lat': coords[1].lat,
        'lon': coords[1].lng
      },
      'bottom_rigth': {
        'lat': coords[3].lat,
        'lon': coords[3].lng
      }
    };

    if (type) {
      retData['format'] = type;
    }

    return retData;
  }

  private getDataPointService(bbox, h, w, x, y) {
    const retData = {
      'bbox': bbox ,
      'height': String(h),
      'width': String(w),
      'x': String(x),
      'y': String(y)
    };
    return retData;
  }

  private getDataArea(coords) {
    this.hideResultPanels();
    this.showDisabled = true;
    if (this.customVariable) {
      // query service custom
      const country   = this.localSess.default_country.id;
      const variable  = this.custom.id;
      this.service.getAreaDataCustom(country, variable, coords).subscribe( areaDataCustom => {
        this.showDisabled = false;
        this.retTotalArea = areaDataCustom;
        this.showRegionResult = true;
      });
    } else {
      // query service
      this.service.getAreaData(this.idProjection, coords).subscribe( areaData => {
        this.showDisabled = false;
        this.retTotalArea = areaData;
        this.showRegionResult = true;
      });
    }
  }

  private deleteLayer() {
    if (this.actualLayer) { this.localMap.removeLayer(this.actualLayer); }
  }

  private hideResultPanels() {
    this.showPointResult = false;
    this.showRegionResult = false;
  }

  private downloadFiles(file, fileName) {
    const blobFile = new Blob([file]);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blobFile);
      const nameArchive = fileName;
      link.setAttribute('href', url);
      link.setAttribute('download', nameArchive);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private setFileName(type) {
    const today = new Date();
    const formatToday = formatDate( today,  'yyyy_MM_dd_hhmm', 'en-US' );
    return 'intrabase_archive_' + type + '_' + formatToday + '.csv';
  }

  private getCustomUrlMap(url) {
    return url.replace('{country}', this.localSess.default_country.id)
                .replace('{variable}', this.custom.id);
  }

  private createCustomLegend() {
    const custom = new Control({position: 'bottomright'});
    custom.onAdd = function () {
      const div = DomUtil.create('div', 'mapInfo mapLegend');
      const labels = ['<strong>Referencia</strong>'],
        categories = [
          'Alta',
          'Media',
          'Baja'];
      const colors = ['#6EEB83', '#1BE7FF', '#F4F149'];
      for (let i = 0; i < categories.length; i++) {
        div.innerHTML += '<i style="background:' + colors[i] + '"></i> ' + categories[i] + '<br>';
      }
      return div;
    };

    return custom;
  }

  get fileType() {
    return this.fTypeFileCustom.get('fTypeFieldCustom');
  }
}
