<div class="row mrg-btm-10 mrg-top-15">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-world"></i> Modificar paises</h4></div>
</div>
<div *ngIf="showLoading" class="mrg-top-15">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-info text-center">Cargando datos</div>
  </div>
</div>
<div *ngIf="(!showLoading)" class="row justify-content-center border-1 border-danger" >
  <div class="col-md-4 col-sm-12">
    <h5 class="text-center">Paises disponibles</h5>
    <div class="overflow-y-auto height-100 text-center">
      <ul class="list-members">
        <li *ngFor="let option of countries.toadd; index as i" style="float: none;" [@fadeInOut]>
          <span class="label label-info label-actualquery">{{ option.name }}
            <label class="text-dark pointer"
                   placement="right" ngbTooltip="Agregar"
                   (click)="addItem(i)"><i class="ti-arrow-circle-right"></i></label>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-4 offset-md-1 col-sm-12">
    <h5 class="text-center">Paises seleccionados</h5>
    <div class="overflow-y-auto height-100 text-center">
      <ul class="list-members">
        <li *ngFor="let optionSel of countries.selected; index as e" style="float: none;" [@fadeInOut]>
          <span class="label label-info label-actualquery">
            <label class="text-dark pointer"
                   placement="left" ngbTooltip="Quitar"
                   (click)="removeItem(e)"><i class="ti-arrow-circle-left"></i></label> {{ optionSel.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="showResult" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-success animated zoomIn text-center">Se modficaron los datos</div>
  </div>
</div>
<div *ngIf="(!showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="saveChanges()" class="btn btn-intrabase btn-rounded btn-inverse">Grabar</label></li>
      <li class="list-inline-item"><label data-dismiss="modal" class="btn btn-danger btn-rounded btn-inverse">Cerrar</label></li>
    </ul>
  </div>
</div>
