import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../services/purchase.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-downloads-lista',
  templateUrl: './downloads-lista.component.html'
})
export class DownloadsListaComponent implements OnInit {

  allFiles: any;
  loading: boolean;
  moreInfoData: any = { total: null, locations: null, filters: null};

  constructor(private servicePurchase: PurchaseService) {
    // load all files
    this.loading = true;
    this.servicePurchase.getAllPurchased().subscribe(
      allfiles => {
        this.allFiles = allfiles;
        this.loading = false;
      }
    );
  }

  moreInfo(id) {
    this.loading = true;
    // return data
    this.moreInfoData = { total: this.allFiles[id].results,
                          locations: this.allFiles[id].query.location_filters,
                          filters: this.allFiles[id].query.variable_filters,
                          filetype: this.allFiles[id].format };
    this.loading = false;
  }

  downloadArchivedFile(id, total) {
    this.servicePurchase.downloadFile(id).subscribe(
      file => {
        const blobFile = new Blob([file]);
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blobFile);
          const nameArchive = this.getFileName(total);
          link.setAttribute('href', url);
          link.setAttribute('download', nameArchive);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    );
  }

  private getFileName(total) {
    const today = new Date();
    const formatToday = formatDate( today,  'yyyy_MM_dd_hhmm', 'en-US' );
    return 'intrabase_archive_' + formatToday + '_' + total + '.csv';
  }

  ngOnInit() {
  }

}
