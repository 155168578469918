import { BadInput } from '../shared/common/bad-input';
import { NotFoundError } from '../shared/common/not-found-error';
import { AppError } from '../shared/common/app-error';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Proyecciones } from '../interfaces/proyecciones';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { BadCredentials } from '../shared/common/bad-credentials';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

export class DataService {

  constructor(private url: string, private http: HttpClient) { }

  // Generic GET
  sendGETNoAuth(url, hash) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Basic ' + hash);
    return this.http.get(url, httpOptions)
      .map(response => response)
      .catch(this.handleError);
  }

  // Generic GET
  sendGET(url) {
    httpOptions.headers = this.setHeadersAuth();
    return this.http.get(url, httpOptions)
      .map(response => response)
      .catch(this.handleError);
  }

  // Generic POST
  sendPOST(url, resource?) {
    httpOptions.headers = this.setHeadersAuth();
    return this.http.post(url, resource, httpOptions)
      .map(response => response)
      .catch(this.handleError);
  }

  // get Files
  getFile(url) {
    const httpOptionsFile = {
      headers: new HttpHeaders({
        'Content-Type': 'text/csv',
        'Authorization': 'Basic ' + localStorage.getItem('token')
      })
    };
    return this.http.get(url, { headers: httpOptionsFile.headers, responseType: 'text' })
      .map( response => response )
      .catch(this.handleError);
  }

  getFileByPost(url, resource?) {
    const httpOptionsFile = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + localStorage.getItem('token')
      })
    };
    return this.http.post(url, resource, { headers: httpOptionsFile.headers, responseType: 'text' })
      .map( response => response )
      .catch(this.handleError);
  }

  // Private Methods
  // Add headers
  private setHeadersAuth() {
    return httpOptions.headers.set('Authorization', 'Basic ' + localStorage.getItem('token'));
  }
  // Manage errors
  private handleError(error: Response) {
    if (error.status === 401) {
      return Observable.throw(new BadCredentials(error));
    }
    if (error.status === 400) {
      return Observable.throw(new BadInput(error));
    }

    if (error.status === 404) {
      return Observable.throw(new NotFoundError());
    }
    return Observable.throw(new AppError(error));
  }
  // Replace params
  protected replaceUrlParams (url, param) {
    return url.replace('{id}', param);
  }
}
