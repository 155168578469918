import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filetypeName'
})
export class FileTypeNamePipe implements PipeTransform {

  private retValue: string;

  transform(value: string): string {
    // parse
    this.retValue = '';
    switch (value) {
      case 'COORDINATES':
        this.retValue = 'Coordenadas';
        break;
      case 'ADDRESSES':
        this.retValue = 'Direcciones';
        break;
    }
    //
    return this.retValue;
  }
}
