import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';

import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { RouterModule} from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginService } from './services/login.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Error404Component } from './error404/error404.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { LocationsService } from './services/locations.service';
import { FiltersService } from './services/filters.service';
import { DataSyncComponentsService } from './services/data-sync-components.service';
import { ResultsComponent } from './results/results.component';
import { PurchaseService } from './downloads/services/purchase.service';
import 'd3';
import 'nvd3';
import { NgxWebstorageModule } from 'ngx-webstorage';

// Private modules
import { UsersModule } from './users/users.module';
import { DownloadsModule } from './downloads/downloads.module';
import { ProjectionsModule } from './projections/projections.module';

// shared
import { LocationsNamePipe } from './shared/pipes/locations-name.pipe';

// Import Routes
import { AppRoutes } from './app.routing';
import { HomeComponent } from './home/home.component';
import {CustomModule} from './custom/custom.module';
import {MapService} from './map/services/map.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    Error404Component,
    DashboardComponent,
    LogoutComponent,
    LocationsNamePipe,
    ResultsComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, { enableTracing: false }),
    NgbModule.forRoot(),
    NgSelectModule,
    NgxWebstorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    UsersModule,
    DownloadsModule,
    ProjectionsModule,
    CustomModule,
    BrowserAnimationsModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    LoginService,
    LocationsService,
    DataSyncComponentsService,
    FiltersService,
    PurchaseService,
    NgbActiveModal,
    MapService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
