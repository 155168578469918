import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../services/users.service';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';

@Component({
  selector: 'app-usuarios-lista',
  templateUrl: './usuarios-lista.component.html'
})
export class UsuariosListaComponent implements OnInit {

  users: any;
  usersLoaded;
  modals;
  user;
  userId: number;
  userData: any;
  userIdx: number;
  userCountries: any = { selected: [], all: [], toadd: [] };
  showLoading: boolean;

  constructor(private service: UsersService, private sync: DataSyncComponentsService, private modal: NgbModal) {
    // Load userseee
    this.usersLoaded = false;
    this.service.getAllUsers().subscribe( users => {
      this.users = users;
      this.usersLoaded = true;
      this.sync.changeUsers(this.users);
    });
  }

  refreshDataModalExtended(data, id, i) {
    //
    this.showLoading = true;
    this.userData = data;
    this.userId = id;
    this.userIdx = i;
    this.service.getCounriesForUser(this.userId).subscribe(
      result => {
        this.userCountries.selected = result;
        this.service.getAllCountries().subscribe(
          resultnew => {
            this.userCountries.all = resultnew;
            this.userCountries.toadd = this.userCountries.all.filter(
                                      item => !this.userCountries.selected.some(other => item.id === other.id));
            this.showLoading = false;
          });
      });
    //
  }

  refreshDataModal(data, id, i) {
    this.userData = data;
    this.userId = id;
    this.userIdx = i;
  }

  ngOnInit() {
    this.sync.currentUsers.subscribe( users => this.users = users);
    this.sync.currentModals.subscribe(modals => this.modals = modals);
  }

}
