<div class="row mrg-btm-10 mrg-top-15">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-world"></i> Modificar filtros</h4></div>
</div>
<div *ngIf="showLoading" class="mrg-top-15">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-info text-center">Cargando datos</div>
  </div>
</div>
<form [formGroup]="fSelects">
  <div class="row mrg-btm-15">
    <div class="col-md-12">
      <div class="form-group mrg-right-20">
        <label>Paises:</label>
        <ng-select placeholder="Seleccione el pais..."
                   [hideSelected]="true"
                   [clearable]="false"
                   formControlName="fCountry"
                   (change)="onSelectCountry($event)">
          <ng-option *ngFor="let option of countries.selected ; index as e" [value]="e" >
            {{ option.name }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row mrg-btm-15">
    <div class="col-md-12">
      <div class="form-group mrg-right-20">
        <label>Categorias:</label>
        <ng-select placeholder="Seleccione la categoria..."
                   [hideSelected]="true"
                   [clearable]="false"
                   formControlName="fCategory"
                   (change)="onSelectCategory($event)">
          <ng-option *ngFor="let optionCat of categoriesCountries  ; index as i" [value]="i" >
            {{ optionCat.description }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
</form>
<div class="row justify-content-center border-1 border-danger" >
  <div class="col-md-4 col-sm-12">
    <h5 class="text-center">Filtros disponibles</h5>
    <div class="overflow-y-auto overflow-x-hidden text-center" style="height: 300px;">
      <ul class="list-members">
        <li *ngFor="let option of filtersAvaliable; index as i" style="float: none;" [@fadeInOut]>
        <span class="label label-info label-actualquery">{{ option.description }}
          <label class="text-dark pointer"
                 placement="right" ngbTooltip="Agregar"
                 (click)="addItem(i)"><i class="ti-arrow-circle-right"></i></label>
        </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-4 offset-md-1 col-sm-12">
    <h5 class="text-center">Filtros seleccionados</h5>
    <div class="overflow-y-auto overflow-x-hidden text-center" style="height: 300px;">
      <ul class="list-members">
        <li *ngFor="let optionSel of filtersSelected; index as e" style="float: none;" [@fadeInOut]>
        <span class="label label-info label-actualquery">
          <label class="text-dark pointer"
                 placement="left" ngbTooltip="Quitar"
                 (click)="removeItem(e)"><i class="ti-arrow-circle-left"></i></label> {{ optionSel.description }}
        </span>
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="showResult" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-success animated zoomIn text-center">Se modficaron los datos</div>
  </div>
</div>
<div *ngIf="(!showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="saveChanges()" class="btn btn-intrabase btn-rounded btn-inverse">Grabar</label></li>
      <li class="list-inline-item"><label data-dismiss="modal" class="btn btn-danger btn-rounded btn-inverse">Cerrar</label></li>
    </ul>
  </div>
</div>
