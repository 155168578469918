<div class="row mrg-btm-10 mrg-top-25">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-lock"></i> Cambiar contraseña</h4></div>
</div>
<div class="row">
  <div class="col-md-12 col-sm-12">
    <form [formGroup]="fPassword">
      <div class="form-group mrg-right-10">
        <label>Nueva contraseña</label>
        <input formControlName="fNewPassword" type="password" class="form-control" placeholder="Contraseña" >
      </div>
      <div class="form-group mrg-right-10">
        <label>Repetir nueva contraseña</label>
        <input formControlName="fRepeatNewPassword" type="password" class="form-control" placeholder="Repetir contraseña" >
      </div>
    </form>
  </div>
</div>
<div *ngIf="(showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-success animated zoomIn text-center">Se cambio la contraseña</div>
  </div>
</div>
<div *ngIf="(showError)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-danger animated zoomIn text-center">No coinciden las contraseñas</div>
  </div>
</div>
<div *ngIf="(!showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="onChangePassword();" class="btn btn-intrabase btn-rounded btn-inverse">Grabar</label></li>
      <li class="list-inline-item"><label data-dismiss="modal" class="btn btn-danger btn-rounded btn-inverse">Cerrar</label></li>
    </ul>
  </div>
</div>
