<div class="row mrg-btm-10 mrg-top-25">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-na"></i> Modificar estado</h4></div>
</div>
<div class="row mrg-top-25">
  <div class="col-md-12 col-sm-12">
    <form [formGroup]="fStatus">
      <div class="row">
        <div class="col-md-4 text-right">
          <label class="text-gray">Inactivo</label>
        </div>
        <div class="col-md-4 text-center">
          <div class="toggle-checkbox toggle-primary checkbox-inline">
            <input formControlName="fNewStatus" type="checkbox" name="status" id="status" [checked]="data">
            <label for="status"></label>
          </div>
        </div>
        <div class="col-md-4 text-left">
          <label class="text-gray">Activo</label>
        </div>
      </div>

    </form>
  </div>
</div>
<div *ngIf="(showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-success animated zoomIn text-center">Se modifico el estado</div>
  </div>
</div>
<div *ngIf="(!showResult)" class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="onUdpStatus();" class="btn btn-intrabase btn-rounded btn-inverse">Grabar</label></li>
      <li class="list-inline-item"><label data-dismiss="modal" class="btn btn-danger btn-rounded btn-inverse">Cerrar</label></li>
    </ul>
  </div>
</div>
