<!-- Filters -->
<div class="row" [ngClass]="(filtersSelected.length>0 && filtersCustomSelected.length===0) ? 'd-block' : 'd-none'">
  <div class="col-md-12">
    <div class="card">
      <div class="card-heading">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <h4 class="card-title"><i class="ti-settings"></i> Variables para filtros</h4>
          </div>
          <div class="col-md-4 col-sm-12">
            <div [className]="classFade">
              <div class="alert alert-info text-center">Variable ya seleccionada!!!!</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Filters -->
      <div class="card-footer no-border">
        <div class="row overflow-x-hidden overflow-y-auto">
          <div class="col-md-12">
            <div id="{{ filter.name }}" *ngFor="let filter of filtersSelected; index as i"
                 class="row border-bottom fadeInRight" style="margin-top: 20px; margin-left: 0px; margin-right: 0px;" >
              <div class="col-md-12">
                <app-filter-chart [filterData]="filter" [chartDataIn]="filter" [indexFilter]="i" ></app-filter-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Filters Custom -->
      <div class="card-footer no-border">
        <div class="row overflow-x-hidden overflow-y-auto">
          <div class="col-md-12">
            <div id="{{ filterCustom.id }}" *ngFor="let filterCustom of filtersCustomSelected; index as i"
                 class="row border-bottom fadeInRight" style="margin-top: 20px; margin-left: 0px; margin-right: 0px;" >
              <div class="col-md-10">
                <p class="text-bold" style="margin-bottom: 0px; !important">{{ filterCustom.name }}</p>
                <div *ngFor="let value of filterCustom.slices"  class="checkbox checkbox-inline checkbox-primary mrg-top-5">
                  <label class="label label-info">
                    <span>{{ value.obj }}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-2 text-right align-self-center">
                <a href="javascript:void(0);" (click)="onDeleteFilter(i)"
                   class="btn btn-rounded btn-danger">Quitar</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Filters Custom -->
<div class="row" [ngClass]="(filtersCustomSelected.length>0) ? 'd-block' : 'd-none'">
  <div class="col-md-12">
    <div class="card">
      <div class="card-heading">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <h4 class="card-title"><i class="ti-settings"></i> Variables Custom</h4>
          </div>
        </div>
      </div>
      <div class="card-footer no-border">
        <div class="row overflow-x-hidden overflow-y-auto">
          <div class="col-md-12">
            <div id="{{ filter.id }}" *ngFor="let filter of filtersCustomSelected; index as i"
                 class="row border-bottom fadeInRight" style="margin-top: 20px; margin-left: 0px; margin-right: 0px;" >
              <div class="col-md-10">
                <p class="text-bold" style="margin-bottom: 0px; !important">{{ filter.name }}</p>
                <div *ngFor="let value of filter.slices"  class="checkbox checkbox-inline checkbox-primary mrg-top-5">
                  <label class="label label-info">
                    <span>{{ value.obj }}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-2 text-right align-self-center">
                <a href="javascript:void(0);" (click)="onDeleteFilterCustom(i)"
                   class="btn btn-rounded btn-danger">Cerrar Custom</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
