import { Component, OnInit } from '@angular/core';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filters-category',
  templateUrl: './filters-category.component.html'
})
export class FiltersCategoryComponent implements OnInit {

  categoriesCmb: any;
  variablesCmb: any;
  categorySelected: any;
  variableSelected: any;
  filtersSelected: any;
  boolCatExists: boolean;
  classFade: string;

  constructor(private sync: DataSyncComponentsService) { this.boolCatExists = false; this.classFade = 'd-none'; }

  // Init Controls
  fSelects = new FormGroup( {
    fCategory: new FormControl(),
    fVariables: new FormControl()
  });

  onSelectCategory (event) {
    this.categorySelected = event;
    this.clearControls('fVariables');
    this.variablesCmb = this.categoriesCmb.all[event].variables;
  }

  onSelectVariable (event) {
    this.variableSelected = event;
  }

  onAddVariable() {
    // create variable
    const itemDesc = this.categoriesCmb.all[this.categorySelected].description + '_'
      + this.categoriesCmb.all[this.categorySelected].variables[this.variableSelected].name;
    const itemId = this.categoriesCmb.all[this.categorySelected].variables[this.variableSelected].id;

    // check if variable exists in list
    if (!this.checkIfVariableExists(itemDesc)) {
      const items = this.categoriesCmb.all[this.categorySelected].variables[this.variableSelected].slices;
      // add to filters
      this.filtersSelected.unshift({'name': itemDesc, 'items': items, 'id': itemId});
    } else {
      const oVariableName = itemDesc.split('_');
      this.classFade = 'animated fadeInRight';
      setTimeout(() => {
        this.classFade = 'animated fadeOutRight';
      }, 3000);
    }
  }

  checkIfVariableExists(item) {
    //
    const retFilter = this.filtersSelected.find( option => option.name === item );
    //
    if (retFilter === undefined) {
      return false;
    } else {
      return true;
    }
  }

  resetControls() {
    this.clearControls('fVariables');
  }

  clearControls(control) {
    this.fSelects.get(control).reset();
    this.variablesCmb = [];
  }

  resetAll() {
    this.clearControls('fVariables');
    this.clearControls('fCategory');
  }

  ngOnInit() {
    this.sync.currentChangedCountry.subscribe( flag => {
      if (flag) {
        this.resetAll();
      }
    });
    this.sync.currentCategoriesCmb.subscribe( categoriesCombo => this.categoriesCmb = categoriesCombo );
    this.sync.currentFiltersSelected.subscribe( filtersSelected => this.filtersSelected = filtersSelected );
  }

}
