import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DownloadsRoutes } from './downloads.routing';
import { DownloadsMasterComponent } from './downloads-master/downloads-master.component';
import { DownloadsListaComponent } from './downloads-lista/downloads-lista.component';
import { DownloadLocationsPipe } from '../shared/pipes/download-locations.pipe';
import { FileTypeNamePipe } from '../shared/pipes/file-type-name.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(DownloadsRoutes),
    LeafletModule,
    SharedModule
  ],
  declarations: [
    DownloadsMasterComponent,
    DownloadsListaComponent,
    DownloadLocationsPipe,
    FileTypeNamePipe
  ],
  exports: [
    RouterModule
  ]
})

export class DownloadsModule { }
