import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {DataSyncComponentsService} from '../../services/data-sync-components.service';
import {UsersService} from '../services/users.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-usuarios-countries',
  templateUrl: './usuarios-countries.component.html',
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)),
    ])
  ]
})

export class UsuariosCountriesComponent implements OnInit {

  @LocalStorage('session') localSess: any;
  @Input() data: any;
  @Input() id: number;
  @Input() idx: number;
  @Input() countries: any;
  @Input() showLoading: boolean;

  showResult: boolean;

  constructor(private sync: DataSyncComponentsService, private serviceUsers: UsersService) { }

  saveChanges() {
    const countriesParsed = this.getCountriesSelected(this.countries.selected);
    this.serviceUsers.setCountriesForUser(this.id, countriesParsed).subscribe(
      result => {
        this.showResult = true;
        setTimeout(() => {
          this.showResult = false;
        }, 3000);
      }
    );
  }

  removeItem(item) {
    this.countries.toadd.push(this.countries.selected[item]);
    this.countries.selected.splice(item, 1);
  }

  addItem(item) {
    this.countries.selected.push(this.countries.toadd[item]);
    this.countries.toadd.splice(item, 1);
  }

  private getCountriesSelected(obj) {
    let ret = '';
    for (const item of obj) {
      ret += '"' + item.id + '"' + ',';
    }
    return '[' + ret.slice(0, -1) + ']';
  }

  ngOnInit() {
    this.showResult = false;
  }

}
