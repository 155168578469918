<div class="row">
  <div class="col-md-3 col-sm-12">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title"><i class="ti-settings"></i> Acciones</h4>
          </div>
          <div class="card-footer no-border">
            <ul class="list-unstyled list-inline text-left pdd-vertical-5">
              <li class="list-inline-item"><label [ngClass]="(filtersCustomSelected.length>0) ? 'd-none' : 'd-block'"
                                              class="btn btn-primary btn-inverse btn-rounded"
                                              data-toggle="modal"
                                              data-target="#modLocations">
                                              <i class="ti-plus"></i> Ubicaciones</label></li>
              <li class="list-inline-item"><label [ngClass]="(filtersCustomSelected.length>0) ? 'd-none' : 'd-block'"
                                              class="btn btn-primary btn-inverse btn-rounded"
                                              data-toggle="modal"
                                              data-target="#modFilters">
                                              <i class="ti-plus"></i> Filtros</label></li>
            </ul>
            <div *ngIf="(categoriesCombo.custom)" class="row">
              <div class="col-md-12 mrg-top-10">
                <label class="btn btn-warning btn-inverse btn-rounded btn-block"
                                                    data-toggle="modal"
                                                    data-target="#modCustomFilters">
                  <i class="ti-plus"></i> Filtros Custom</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-actual-query></app-actual-query>
  </div>
  <div class="col-md-9 col-sm-12">
    <app-filters></app-filters>
  </div>
</div>
<!-- Modal for locations -->
<div class="modal slide-in-right modal-right fade" id="modLocations">
  <div class="modal-dialog" role="document">
    <div class="modal-content" >
      <div class="side-modal-wrapper">
        <div class="vertical-align">
          <app-selectcoord></app-selectcoord>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for filters -->
<div class="modal slide-in-right modal-right fade" id="modFilters">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="side-modal-wrapper">
        <div class="vertical-align">
          <app-filters-category></app-filters-category>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for Custom filters -->
<div class="modal slide-in-right modal-right fade" id="modCustomFilters">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="side-modal-wrapper">
        <div class="vertical-align">
          <app-custom-filters></app-custom-filters>
        </div>
      </div>
    </div>
  </div>
</div>

