import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'downloadLocations'
})
export class DownloadLocationsPipe implements PipeTransform {

  retValue: string;

  transform(value: any): string {
    // parse
    this.retValue = '';
    if ( value.location_l2 !== null) {
      this.retValue += ' Nivel 1: ' + value.location_l2;
    }
    if ( value.location_l3 !== null) {
      this.retValue += ' Nivel 2: ' + value.location_l3;
    }
    if ( value.location_l4 !== null) {
      this.retValue += ' Nivel 3: ' + value.location_l4;
    }
    if ( value.location_l5 !== null) {
      this.retValue += ' Nivel 4: ' + value.location_l5;
    }
    //
    return this.retValue;
  }
}
