<div *ngIf="(actualQuery.locations.length>0 && filtersCustomSelected.length===0)" class="row mrg-top-30">
  <div class="col-md-12">
    <a href="javascript:void(0);"
       data-toggle="modal"
       data-target="#results-r"
       class="btn btn-lg btn-intrabase btn-rounded"
       (click)="getResults()">PROYECTAR</a>
  </div>
</div>
<div *ngIf="(filtersCustomSelected.length>0)" class="row mrg-top-30">
  <div class="col-md-12">
    <app-map [idProjection]="-1" [beginCoords]="'-1'" [originCall]="'projectionCustom'" [custom]="actualQuery.customFilters[0]"></app-map>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <a *ngIf="(allQueries.length > 1)"
      class="btn btn-default btn-inverse btn-rounded"
      href="javascript:void(0);"
      data-toggle="modal"
      data-target="#queryRecent"
      (click)="openRecentQuerys()"><i class="ti-bar-chart"></i> Recientes</a>
  </div>
</div>
<!-- Modal Results -->
<div class="modal fade " id="results-r">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="side-modal-wrapper padding-50">
        <div *ngIf="(loading==true)" class="row mrg-top-10">
          <div class="col-md-12 text-center">
            <img src="../../../assets/images/loading_big.gif" /><br>
            <h2>{{msgLoading}}</h2>
          </div>
        </div>
        <!-- Proyeccion Results -->
        <div *ngIf="(loading==false && showProyeccion==true)" class="row">
          <div class="col-md-12 text-center">
            <div class="row">
              <div class="col-md-12 text-left">
                <h4 class="mrg-btm-20"><i class="ti-server"></i> Resultados</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card bg-homes-projections">
                  <div class="card-block">
                    <div class="row">
                        <div class="col-md-5">
                          <p class="text-uppercase font-size-18 text-left">Hogares</p>
                        </div>
                        <div class="col-md-7">
                          <p class="text-uppercase font-size-40 text-right">{{dashboardResult.total_hogares}}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card bg-gray">
                  <div class="card-block">
                    <div class="row">
                      <div class="col-md-5">
                        <p class="text-uppercase font-size-18 text-left">Ubicaciones</p>
                      </div>
                      <div class="col-md-7">
                        <p class="text-uppercase font-size-30 text-right">{{dashboardResult.total_ubicaciones}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Download button -->
            <div *ngIf="(showConfirm==false)" class="row">
              <div class="col-md-12">
                <a *ngIf="(localSess.credits>0)" href="javascript:void(0);" (click)="confirmDownload()" class="btn btn-block btn-rounded btn-inverse btn-primary text-uppercase">generar archivo</a>
              </div>
            </div>
            <!-- Confirm download -->
            <div *ngIf="(showConfirm==true)" class="row">
              <form [formGroup]="fTypeFile">
                <div class="col-md-12 text-center">
                  Tipo de archivo:
                  <div class="radio radio-inline radio-primary mrg-left-10">
                    <input type="radio" formControlName="fTypeFileField" id="coords" value="COORDENATES" [checked]="true">
                    <label for="coords">Coordenadas</label>
                  </div>
                  <div class="radio radio-inline radio-primary">
                    <input type="radio" formControlName="fTypeFileField" value="ADDRESSES" id="adress">
                    <label for="adress">Direcciones</label>
                  </div>
                </div>
              </form>
              <div class="col-md-12 text-center font-size-18">
                <p>Se consumiran <b>{{dashboardResult.total_ubicaciones}}</b> creditos ¿Estas seguro?</p>
                <p>
                  <a (click)="generateDownload()" href="javascript:void(0);" class="btn btn-success btn-inverse text-uppercase">si</a>
                  <a (click)="negateConfirm()" href="javascript:void(0);" class="btn btn-danger btn-inverse text-uppercase">no</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Archivo Results -->
        <div *ngIf="(loading==false && showArchivo==true)" class="row">
          <div class="col-md-12 text-center">
            <div class="row">
              <div class="col-md-12 text-left">
                <h4 class="mrg-btm-20"><i class="ti-cloud-down"></i> Descargar Archivo</h4>
              </div>
            </div>
            <div class="row mrg-top-30">
              <div class="col-md-12 text-center">
                <a href="{{downloadUrl}}">
                  <i class="ti-file font-size-150"></i>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center mrg-top-10">
                <a href="javascript:void(0);" (click)="downloadFile()" class="btn btn-block btn-inverse btn-primary btn-rounded text-uppercase">descargar</a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center mrg-top-10">
                <app-map [idProjection]="purchase.id" [beginCoords]="purchase.bounds" [originCall]="'projection'" [custom]="'-1'"></app-map>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right mrg-top-50 mrg-right-10">
                <label>Creditos Consumidos: <b>{{purchase.credits.consumed}}</b></label>
                <br>
                <label>Creditos Restantes: <b>{{purchase.credits.remaining}}</b></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(loading==false)" class="modal-footer">
        <div class="row">
          <div class="col-md-12">
            <a href="javascript:void(0);" class="btn btn-block btn-rounded btn-inverse btn-intrabase text-uppercase" data-dismiss="modal">seguir proyectando</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Chart Modal -->
<div class="modal fade" id="queryRecent">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card no-border">
            <div class="card-heading border bottom">
              <h4 class="card-title">Proyecciones Recientes</h4>
            </div>
            <div class="card-block">
              <nvd3 [options]="chartOptions" [data]="chartData"></nvd3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card no-border">
            <div class="card-heading border bottom">
              <h4 class="card-title">Detalle de las Proyecciones</h4>
            </div>
            <div class="card-block">
              <div class="tab-info center-tabs">
                <ul class="nav nav-tabs" role="tablist">
                  <li *ngFor="let projection of allQueries; index as i" class="nav-item">
                    <a href="#PR_{{ i }}"
                       [ngClass]="(i==0) ? 'nav-link active' : 'nav-link'"
                      role="tab" data-toggle="tab">PR_{{ i }}</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div *ngFor="let projection_data of allQueries; index as e" role="tabpanel"
                       [ngClass]="(e==0) ? 'tab-pane fade in active' : 'tab-pane fade'"
                       id="PR_{{ e }}">
                    <div class="pdd-horizon-15 pdd-vertical-20 text-center">
                      <ul class="list-members">
                        <li *ngFor="let option of allQueries[e].dataView.locations; index as k">
                          <span class="label label-info">{{option.label}}</span>
                        </li>
                        <li *ngFor="let optionFilters of allQueries[e].dataView.filters; index as j">
                          <!-- TODO: add pipe FilterNamePipe -->
                          <span class="label label label-primary">{{ optionFilters.value  }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
