<div class="row padding-20">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-filter"></i> Filtros Custom</h4></div>
</div>
<div class="row">
  <div class="col-md-12 col-sm-12">
    <form [formGroup]="fSelects">
      <div class="form-group mrg-right-10">
        <label>Categorias</label>
        <ng-select placeholder="Seleccione la categoria..."
                   [hideSelected]="true"
                   [clearable]="false"
                   formControlName="fVariables"
                   (change)="onSelectCustom($event)">
          <ng-option *ngFor="let option of categoriesCmb.custom.variables ; index as e" [value]="e" >
            {{ option.name }}
          </ng-option>
        </ng-select>
      </div>
    </form>
  </div>
</div>
<div class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="onAddCustomVariable();" class="btn btn-intrabase btn-rounded">Agregar</label></li>
      <li class="list-inline-item"><label (click)="resetControls()"data-dismiss="modal" class="btn btn-danger btn-rounded">Cerrar</label></li>
    </ul>
  </div>
</div>
<div class="row mrg-top-40">
  <div class="card bg-gray mrg-left-15 mrg-right-15">
    <div class="card-block text-center">
      <h5>Los filtros y ubicaciones seleccionados se ocultaran al agregar una variable Custom, pero <b>NO</b> se perderan.</h5>
    </div>
  </div>
</div>

