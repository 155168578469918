import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UsersRoutes } from './users.routing';
import {UsuariosMasterComponent} from './usuarios-master/usuarios-master.component';
import {UsuariosListaComponent} from './usuarios-lista/usuarios-lista.component';
import {UsuariosNuevoComponent} from './usuarios-nuevo/usuarios-nuevo.component';
import {UsuariosCreditsComponent} from './usuarios-credits/usuarios-credits.component';
import {UsersListaPipe} from './pipes/users-lista.pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from './services/users.service';
import { ArchwizardModule } from 'angular-archwizard';
import {NgSelectModule} from '@ng-select/ng-select';
import {UsuariosStatusComponent} from './usuarios-status/usuarios-status.component';
import {UsuariosPasswordComponent} from './usuarios-password/usuarios-password.component';
import {UsuariosFiltersComponent} from './usuarios-filters/usuarios-filters.component';
import {UsuariosCountriesComponent} from './usuarios-countries/usuarios-countries.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(UsersRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    NgSelectModule

  ],
  declarations: [
    UsuariosMasterComponent,
    UsuariosListaComponent,
    UsuariosCreditsComponent,
    UsuariosNuevoComponent,
    UsuariosStatusComponent,
    UsuariosPasswordComponent,
    UsuariosFiltersComponent,
    UsuariosCountriesComponent,
    UsersListaPipe,
  ],
  providers: [
    UsersService
  ],
  exports: [
    RouterModule
  ]
})
export class UsersModule { }
