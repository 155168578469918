import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProjectionsRoutes } from './projections.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { VariableNamePipe } from './pipes/variableName.pipe';
import { FiltersNamePipe } from './pipes/filters-name.pipe';
import { SelectcoordComponent } from './selectcoord/selectcoord.component';
import { QueryMasterComponent } from './query-master/query-master.component';
import { ActualQueryComponent } from './actual-query/actual-query.component';
import { FiltersComponent } from './filters/filters.component';
import { ProyectarComponent } from './proyectar/proyectar.component';
import { NvD3Module } from 'ng2-nvd3';
import { ToastyModule } from 'ng2-toasty';
import { FiltersCategoryComponent } from './filters-category/filters-category.component';
import {CustomFiltersComponent} from './custom-filters/custom-filters.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ProjectionsRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NvD3Module,
    ToastyModule.forRoot(),
    SharedModule
  ],
  declarations: [
    FiltersNamePipe,
    VariableNamePipe,
    QueryMasterComponent,
    SelectcoordComponent,
    ActualQueryComponent,
    FiltersComponent,
    ProyectarComponent,
    FiltersCategoryComponent,
    CustomFiltersComponent
  ]
})
export class ProjectionsModule { }
