<div class="row">
  <div class="col-md-12">
    <div class="card padding-20">
      <h4 class="card-title mrg-btm-20"><i class="ti-filter"></i> Filtros Custom</h4>
      <ul class="list-members">
        <li class="text-center padding-30">
          <i class="ti-file font-size-80"></i>
          <br>
          <label>Total proyeccion: 5235</label>
          <br>
          <label class="download-list-info"><span> Porencialidad de Compra</span></label>
          <br>
          <label
             (click)="customArchivedFile()"
             class="btn btn-primary btn-inverse btn-icon btn-rounded">
            <i class="ti-download"></i>
          </label>
          <app-map [idProjection]="'-1'"
                   [beginCoords]="'-1'"
                   [originCall]="'downloads'"
                   [custom]="'PEUGEOT_208'"></app-map>
        </li>
      </ul>
    </div>
  </div>
</div>
