import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { FormControl } from '@angular/forms';
import { FormGroup} from '@angular/forms';
import {LocationsService} from '../services/locations.service';
import {DataSyncComponentsService} from '../services/data-sync-components.service';

// TODO: migrate storage to https://github.com/PillowPillow/ng2-webstorage

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  public app: any;
  public headerThemes: any;
  public sidenavThemes: any;
  public headerSelected: any;
  public sidenavSelected: any;

  locations: any; categoriesCombo: any; actualQuery: any; selectsLocations: any; chartData: any;

  // _
  session: any;
  @LocalStorage('session') localSess: any;
  // _
  fSelect = new FormGroup({
    countries: new FormControl()
  });
  // _
  constructor(private router: Router, private service: LocationsService, private sync: DataSyncComponentsService) {
    // Check if logged in
    if (localStorage.getItem('token') === null) {
      this.router.navigate(['/logout']);
    }

    this.app = {
      layout: {
        sidePanelOpen: false,
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };
    this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    this.headerSelected = 'header-intrabase';

    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.sidenavSelected = 'sidenav-default';
  }

  onSelect(event) {
    // get selected and change session
    this.localSess.default_country = this.localSess.countries[event];
    const dataQuery = { 'country_id': this.localSess.default_country.id };
    this.sync.changeActualQuery({locations: [], filters: [], customFilters: [], totalData: []});
    this.service.getSelectsLocation(dataQuery).subscribe(
      locations => {
        this.selectsLocations = locations;
        this.sync.changeFiltersCustomSelected([]);
        const arrLocations = this.actualQuery;
        arrLocations.locations.push({ id: 'COUNTRY',
                                      label: this.localSess.default_country.name,
                                      value: this.localSess.default_country.id });
        // Load categories combo
        this.sync.changeCategoriesCmb(this.filterCategories(this.selectsLocations.data_category_filters));
        // Sync locations
        this.sync.changeLocations(arrLocations);
        // Emitt flag
        this.sync.changeCountry(true);
        // Change data for charts
        this.sync.changeChartData([]);
      }
    );
  }

  private filterCategories (oFilters) {
    let custom = {};
    if (oFilters.some(e => e.name === 'CUSTOM')) {
      custom = oFilters.filter(e => e.name === 'CUSTOM');
    }
    return {'all' : oFilters.filter(e => e.name !== 'CUSTOM'), 'custom' : custom};

  }

  ngOnInit() {
    this.sync.currentLocations.subscribe( locations => this.locations = locations );
    this.sync.currentCategoriesCmb.subscribe( categoriesCombo => this.categoriesCombo = categoriesCombo );
    this.sync.currentActualQuery.subscribe( query => this.actualQuery = query );
    this.sync.currentChartData.subscribe( data => this.chartData = data );
  }
}
