import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {LocalStorage} from 'ngx-webstorage';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-usuarios-status',
  templateUrl: './usuarios-status.component.html'
})
export class UsuariosStatusComponent implements OnInit {

  @LocalStorage('session') localSess: any;
  @Input() data: any;
  @Input() id: number;
  @Input() idx: number;

  users: any;
  showResult: boolean;
  retStatus: any;

  constructor(private sync: DataSyncComponentsService, private serviceUsers: UsersService) { }

  // Init Controls
  fStatus = new FormGroup( {
    fNewStatus: new FormControl()
  });


  onUdpStatus() {
    let actionPost = '';
    const action = this.newStatus.value;
    if (action !== null) {
      switch (action) {
        case true:
          actionPost = 'enable';
          break;
        case false:
          actionPost = 'disable';
          break;
      }
      //
      this.serviceUsers.setUserStatus(this.id, actionPost).subscribe(
        status => {
          this.retStatus = status;
          this.users[this.idx].enabled = this.retStatus.enabled;
          this.showResult = true;
          setTimeout(() => {
            this.showResult = false;
          }, 3500);
        });
    }
  }

  get newStatus() {
    return this.fStatus.get('fNewStatus');
  }

  ngOnInit() {
    this.sync.currentUsers.subscribe( users => this.users = users);
  }

}
