import {Component, Input, OnInit} from '@angular/core';
import { DataSyncComponentsService} from '../services/data-sync-components.service';


@Component({
  selector: 'app-filter-chart',
  templateUrl: './filter-chart.component.html'
})
export class FilterChartComponent implements OnInit {

  @Input() chartDataIn: any;
  @Input() filterData: any;
  @Input() indexFilter: number;

  color: any;
  chartDataLocal: any;

  filtersSelected: any;
  actualQuery: any;
  locationsData: any;
  chartData: any;


  constructor(private sync: DataSyncComponentsService) {
    this.color = {
      domain: ['#FAC51D', '#66BD6D', '#FAA026', '#29BB9C', '#E96B56', '#55ACD2', '#B7332F', '#2C83C9', '#9166B8', '#92E7E8']
    };
  }

  onDeleteFilter(idx) {
    this.filtersSelected.splice(idx, 1);
  }

  selOptionFilter(selID, selName, selValue) {
    // check if exists in filters
    const oActualFilters = this.actualQuery.filters;
    if (this.checkIfInQuery(oActualFilters, selID, selValue) === false) {
      oActualFilters.push({key: selName, value: selValue, id: selID});
      this.actualQuery.filters = oActualFilters;
    } else {
      // show toasty
      /*const oVariableName = selName.split('_');
      this.classFade = 'animated fadeInRight';
      setTimeout(() => {
        this.classFade = 'animated fadeOutRight';
      }, 3000);*/
    }
  }

  private checkIfInQuery(actualQuery, selID, selValue) {
    let finded = false;
    actualQuery.forEach((value: any) => {
      if (value.id === selID && value.value === selValue) {
        finded = true;
      }
    });
    return finded;
  }

  // For charts
  private calcPercent(totData, parcialData) {
    const calc = parcialData * 100 / totData;
    return Math.ceil(calc);
  }

  ngOnInit() {
    // Sync Data
    this.sync.currentFiltersSelected.subscribe( filtersSelected => this.filtersSelected = filtersSelected );
    this.sync.currentLocations.subscribe( data => this.locationsData = data );
    this.sync.currentChartData.subscribe( data => {
      this.chartData = data;
      let totalData = 0;
      // get total of locations
      this.chartData.forEach((val: any, key: any) => {
        // total for all
        totalData += val.total;
      });
      // empty data
      this.filterData.items.forEach((val: any, key) => {
        val.size = 0;
      });
      // get total for variable
      this.chartData.forEach((val: any, key: any) => {
        const filtered = val.filters.filter(filter => this.filterData.id === filter.id);
        filtered[0].slices.forEach((valfiltered: any, keyfiltered: any) => {
          this.filterData.items[keyfiltered].size += valfiltered.size;
        });
      });
      // update chart
      const arr = Array();
      this.filterData.items.forEach((val: any, key: any) => {
        const percent = this.calcPercent(totalData, val.size);
        const dataArr = { 'name': val.obj, 'value': percent };
        arr.push(dataArr);
      });
      this.chartDataLocal = arr;
      //
    });
    this.sync.currentActualQuery.subscribe( data => this.actualQuery = data );
  }
}
