import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {DataSyncComponentsService} from '../../services/data-sync-components.service';
import {UsersService} from '../services/users.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-usuarios-password',
  templateUrl: './usuarios-password.component.html'
})
export class UsuariosPasswordComponent implements OnInit {

  @LocalStorage('session') localSess: any;
  @Input() data: any;
  @Input() id: number;
  @Input() idx: number;

  users: any;
  showResult: boolean;
  showError: boolean;

  constructor(private sync: DataSyncComponentsService, private serviceUsers: UsersService) { }

  // Init Controls
  fPassword = new FormGroup( {
    fNewPassword: new FormControl(),
    fRepeatNewPassword: new FormControl()
  });

  onChangePassword() {
    // Validate fields
    if (this.newpassword.value !== this.repeatnewpassword.value) {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    } else {
      // change password
      const dataForm = JSON.stringify({ username: this.data.username,
                                          password: this.newpassword.value,
                                          is_admin: this.getTypeOfRole(this.data.roles[0]) });
      this.serviceUsers.editUserPass(dataForm, this.id).subscribe(
        result => {
          this.showResult = true;
          this.cleanForm();
        }
      );
    }
  }

  cleanForm() {
    this.newpassword.reset();
    this.repeatnewpassword.reset();
    setTimeout(() => {
      this.showResult = false;
    }, 3000);
  }

  get newpassword() {
    return this.fPassword.get('fNewPassword');
  }
  get repeatnewpassword() {
    return this.fPassword.get('fRepeatNewPassword');
  }

  private getTypeOfRole(type) {
    return ((type === 'ROLE_ADMIN') ? true : false);
  }

  ngOnInit() {
    this.showError = false;
  }

}
