import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'shared/common/endpoints.enum';

@Injectable()
export class PurchaseService extends DataService {

  purchase: any;
  download: any;
  purchased: any;

  constructor(http: HttpClient) {
    super('', http);
  }

  getIdPurchaseCustom(country, variable, params) {
    const newUrl  = this.replaceUrlCustom(Endpoints.getDownloadCustom , country, variable);
    return this.purchase = this.sendPOST( Endpoints.root + newUrl, params );
  }

  getIdPurchase(params) {
    return this.purchase = this.sendPOST( Endpoints.root + Endpoints.purchase, params );
  }

  downloadFile(id) {
    return this.download = this.getFile( Endpoints.root + Endpoints.downloadFile + id );
  }

  getAllPurchased() {
    return this.purchased = this.sendGET( Endpoints.root + Endpoints.purchased );
  }

  // replace parameters for url
  private replaceUrlCustom (url, country, variable) {
    return url.replace('{country}', country).replace('{variable}', variable);
  }

}
