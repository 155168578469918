<div class="row">
  <div class="col-md-12">
    <div class="card padding-20">
      <h4 class="card-title mrg-btm-20"><i class="ei-users"></i> Usuarios</h4>
      <div *ngIf="(usersLoaded==false)" class="row"><div class="col-sm-12 text-center"> <img src="../../../assets/images/loading_big.gif" /><br><h2>Cargando Usuarios</h2></div></div>
      <div *ngIf="(usersLoaded==true)" class="row">
        <div class="col-sm-12 text-left">
          <!-- New User -->
          <!--<app-usuarios-nuevo></app-usuarios-nuevo>-->
        </div>
      </div>
      <div *ngIf="(usersLoaded==true)" class="row">
        <div class="col-md-12 col-sm-12">
          <div class="table-overflow">
            <table class="table table-striped">
              <thead>
                <th>Nombre</th>
                <th>Permisos</th>
                <th>Status</th>
                <th>Creditos</th>
                <th>Acciones</th>
              </thead>
              <tbody>
                <tr *ngFor="let user of users; index as i">
                  <td>{{user.username}}</td>
                  <td>{{user.roles[0] | usersLista }} </td>
                  <td *ngIf="(user.enabled==true)"><label class="success">Activo</label></td>
                  <td *ngIf="(user.enabled==false)"><label class="danger">Bloqueado</label></td>
                  <td>{{ user.credits }}</td>
                  <td>
                    <label class="btn btn-intrabase btn-inverse btn-rounded btn-icon"
                           data-toggle="modal"
                           data-target="#modCredits"
                           placement="top" ngbTooltip="Agregar credito" tooltipClass="tooltip-intrabase"
                            (click)="refreshDataModal(user.credits, user.id, i)"><i class="ti-money"></i></label>
                    <label class="btn btn-intrabase btn-inverse btn-rounded btn-icon"
                           data-toggle="modal"
                           data-target="#modStatus"
                           placement="top" ngbTooltip="Modificar Estado" tooltipClass="tooltip-intrabase"
                           (click)="refreshDataModal(user.enabled, user.id, i)"><i class="ti-na"></i></label>
                    <label class="btn btn-intrabase btn-inverse btn-rounded btn-icon"
                           data-toggle="modal"
                           data-target="#modPassword"
                           placement="top" ngbTooltip="Mofificar contraseña" tooltipClass="tooltip-intrabase"
                           (click)="refreshDataModal(user, user.id, i)"><i class="ti-lock"></i></label>
                    <!--<label class="btn btn-intrabase btn-inverse btn-rounded btn-icon"
                           data-toggle="modal"
                           data-target="#modFilters"
                           placement="top" ngbTooltip="Mofificar filtros" tooltipClass="tooltip-intrabase"
                           (click)="refreshDataModalExtended(user, user.id, i)"><i class="ti-filter"></i></label>-->
                    <label class="btn btn-intrabase btn-inverse btn-rounded btn-icon"
                           data-toggle="modal"
                           data-target="#modCountries"
                           placement="top" ngbTooltip="Mofificar paises" tooltipClass="tooltip-intrabase"
                           (click)="refreshDataModalExtended(user, user.id, i)"><i class="ti-world"></i></label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for credits -->
<div class="modal slide-in-left modal-left fade" id="modCredits">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="side-modal-wrapper">
        <div class="vertical-align">
          <app-usuarios-credits [data]="userData" [id]="userId" [idx]="userIdx"></app-usuarios-credits>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for status -->
<div class="modal slide-in-left modal-left fade" id="modStatus">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="side-modal-wrapper">
        <div class="vertical-align">
          <app-usuarios-status [data]="userData" [id]="userId" [idx]="userIdx"></app-usuarios-status>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for password -->
<div class="modal slide-in-left modal-left fade" id="modPassword">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="side-modal-wrapper">
        <div class="vertical-align">
          <app-usuarios-password [data]="userData" [id]="userId" [idx]="userIdx"></app-usuarios-password>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for filters -->
<div class="modal fade" id="modFilters">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body height-100">
        <div class="vertical-align">
          <app-usuarios-filters [data]="userData" [id]="userId" [idx]="userIdx" [countries]="userCountries" [showLoading]="showLoading"></app-usuarios-filters>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal for countries -->
<div class="modal fade" id="modCountries">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body height-100">
        <div class="vertical-align">
          <app-usuarios-countries [data]="userData" [id]="userId" [idx]="userIdx" [countries]="userCountries" [showLoading]="showLoading"></app-usuarios-countries>
        </div>
      </div>
    </div>
  </div>
</div>
