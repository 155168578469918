<div class="row padding-20">
  <div class="col-md-12 col-sm-12"><h4><i class="ti-filter"></i> Categorias de filtros</h4></div>
</div>
<div class="row">
  <div class="col-md-12 col-sm-12">
    <form [formGroup]="fSelects">
      <div class="form-group mrg-right-10">
        <label>Categorias</label>
        <ng-select placeholder="Seleccione la categoria..."
                   [hideSelected]="true"
                   [clearable]="false"
                   formControlName="fCategory"
                   (change)="onSelectCategory($event)">
          <ng-option *ngFor="let option of categoriesCmb.all ; index as e" [value]="e" >
            {{ option.description }}
          </ng-option>
        </ng-select>
      </div>
      <div class="form-group mrg-right-10">
        <label>Variables</label>
        <ng-select placeholder="Seleccione la variable..."
                   [hideSelected]="true"
                   [clearable]="false"
                   formControlName="fVariables"
                   (change)="onSelectVariable($event)">
          <ng-option *ngFor="let option of variablesCmb ; index as e" [value]="e" >
            {{ option.name }}
          </ng-option>
        </ng-select>
      </div>
    </form>
  </div>
</div>
<div class="row mrg-top-10">
  <div class="col-sm-12 col-md-12">
    <ul class="list-unstyled text-right list-inline pdd-vertical-5">
      <li class="list-inline-item"><label (click)="onAddVariable();" class="btn btn-intrabase btn-rounded">Agregar</label></li>
      <li class="list-inline-item"><label (click)="resetControls()"data-dismiss="modal" class="btn btn-danger btn-rounded">Cerrar</label></li>
    </ul>
  </div>
</div>
<div class="row mrg-top-10" [className]="classFade">
  <div class="col-sm-12 col-md-12">
    <div class="alert alert-info text-center">Categoria ya seleccionada!!!!</div>
  </div>
</div>

