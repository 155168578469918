import { Component, OnInit } from '@angular/core';
import {DataSyncComponentsService} from '../../services/data-sync-components.service';

@Component({
  selector: 'app-downloads-master',
  templateUrl: './downloads-master.component.html'
})
export class DownloadsMasterComponent implements OnInit {

  constructor(private sync: DataSyncComponentsService) {
    this.sync.changeActualQuery({locations: [], filters: []});
  }

  ngOnInit() {
  }

}
