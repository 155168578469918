import {Component, OnInit, ElementRef, Renderer2, OnChanges} from '@angular/core';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ToastyService, ToastData, ToastOptions, ToastyConfig } from 'ng2-toasty';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html'
})
export class FiltersComponent implements OnInit {

  actualQuery: any;
  filters: any = Array();
  locations: any;
  filtersSelected: any;
  filtersCustomSelected: any;
  categoriesCmb: any;
  variablesCmb: any;
  variablesCmbCustom: any;
  variableCustomSelected;
  showEmptyFilters: boolean; showEmptyFiltersCustom: boolean;
  classFade: string;

  constructor(private sync: DataSyncComponentsService, private elm: ElementRef, private render: Renderer2,
              private toastyService: ToastyService, private toastyConfig: ToastyConfig) {
              this.toastyConfig.theme = 'bootstrap';
              this.showEmptyFilters = true; this.showEmptyFiltersCustom = true;
              this.classFade = 'd-none';
              }

  // Init Controls
  fSelects = new FormGroup( {
      fCategory: new FormControl(),
      fVariables: new FormControl()
  });

  fSelectCustom = new FormGroup({
    fVariableCustom: new FormControl()
  });

  onSelectTab () {
   this.variablesCmbCustom = this.categoriesCmb.custom[0].variables;
  }

  onSelectVariableCustom (event) {
    this.variableCustomSelected = event;
  }

  onAddVariableCustom() {
    // create variable
    const itemDesc = this.categoriesCmb.custom[0].description + '_'
      + this.categoriesCmb.custom[0].variables[this.variableCustomSelected].name;
    const itemId = this.categoriesCmb.custom[0].variables[this.variableCustomSelected].id;
    // check if variable exists in list
    if (!this.checkIfVariableExistsCustom(itemDesc)) {
      const items = this.categoriesCmb.custom[0].variables[this.variableCustomSelected].slices;
      // add to filters
      this.filtersCustomSelected.unshift({'name': itemDesc, 'items': items, 'id': itemId});
    } else {
      const oVariableName = itemDesc.split('_');
      // show toasty
      const toastOptions: ToastOptions = {
        title: 'Variable existe',
        msg: 'Variable: <b>' + oVariableName[0] + ' / ' + oVariableName[1] + '</b>',
        showClose: true,
        timeout: 6000,
        theme: 'bootstrap'
      };
      this.toastyService.error(toastOptions);
    }
  }

  onDeleteFilter(idx) {
    this.filtersSelected.splice(idx, 1);
    if (this.filtersSelected.length === 0) {
      this.showEmptyFilters = true;
    }
  }

  onDeleteFilterCustom(idx) {
    this.filtersCustomSelected.splice(idx, 1);
  }

  checkIfVariableExistsCustom(item) {
    //
    const retFilter = this.filtersCustomSelected.find( option => option.name === item );
    //
    if (retFilter === undefined) {
      return false;
    } else {
      return true;
    }
  }

  selOptionFilter(selID, selName, selValue) {
    // check if exists in filters
    const oActualFilters = this.actualQuery.filters;
    if (this.checkIfInQuery(oActualFilters, selID, selValue) === false) {
      oActualFilters.push({key: selName, value: selValue, id: selID});
      this.actualQuery.filters = oActualFilters;
    } else {
      // show toasty
      const oVariableName = selName.split('_');
      this.classFade = 'animated fadeInRight';
      setTimeout(() => {
        this.classFade = 'animated fadeOutRight';
      }, 3000);
    }
  }

  selOptionFilterCustom (selID, selName, selValue) {
    // check if exists in filters
    const oActualFilters = this.actualQuery.filters;
    if (this.checkIfInQuery(oActualFilters, selID, selValue) === false) {
      oActualFilters.push({key: selName, value: selValue, id: selID});
      this.actualQuery.filters = oActualFilters;
    } else {
      // show toasty
      const oVariableName = selName.split('_');
      const toastOptions: ToastOptions = {
        title: 'Filtro Ya Agregado',
        msg: 'El filtro ya fue seleccionado: ' + oVariableName[0] + '/' + oVariableName[1] + ', valor: ' + selValue,
        showClose: true,
        timeout: 3000,
        theme: 'bootstrap'
      };
      this.toastyService.info(toastOptions);
    }
  }

  checkIfInQuery(actualQuery, selID, selValue) {
    let finded = false;
    actualQuery.forEach((value: any) => {
      if (value.id === selID && value.value === selValue) {
        finded = true;
      }
    });
    return finded;
  }

  ngOnInit () {
    this.sync.currentFilters.subscribe(filters => this.filters = filters );
    this.sync.currentLocations.subscribe(locations => this.locations = locations );
    this.sync.currentActualQuery.subscribe(query => this.actualQuery = query );
    this.sync.currentCategoriesCmb.subscribe( categoriesCombo => this.categoriesCmb = categoriesCombo );
    this.sync.currentVariablesCmb.subscribe( variablesCombo => this.variablesCmb = variablesCombo );
    this.sync.currentFiltersSelected.subscribe( filtersSelected => this.filtersSelected = filtersSelected );
    this.sync.currentFiltersCustomSelected.subscribe( filtersCustom => this.filtersCustomSelected = filtersCustom);
  }
}
