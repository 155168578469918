import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from '../map/map.component';
import { LeafletModule} from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FilterChartComponent } from '../filter-chart/filter-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CategoriesNamePipe } from './pipes/categoriesName.pipe';

@NgModule({
  imports: [
    CommonModule,
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule
  ],
  declarations: [
    MapComponent,
    FilterChartComponent,
    CategoriesNamePipe
  ],
  exports: [
    MapComponent,
    FilterChartComponent
  ]
})
export class SharedModule { }
