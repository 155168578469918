import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-login-form',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  //
  auth: any;
  countries: any;
  loading: boolean;
  @LocalStorage() session: any;

  constructor(private service: LoginService, private router: Router) { this.loading = false; }
  //
  // Form controls
  form = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(3)]),
    password: new FormControl('', Validators.required),
  });
  // Props for Validators
  get username() {
    return this.form.get('username');
  }
  get password() {
    return this.form.get('password');
  }
  // try login
  login() {
    this.loading = true;
    let hashAuth: any;
    hashAuth = btoa(this.form.get('username').value + ':' + this.form.get('password').value);
    this.service.getLogin(hashAuth).subscribe(
      auth => {
        this.auth = auth;
        this.loading = false;
        if ( this.auth.enabled === true ) {
          localStorage.setItem('token', hashAuth);
          // load countries for user
          this.service.getCountriesForUser().subscribe(
            countries => {
                this.countries = countries;
                this.session  = { id: this.auth.id,
                                  username: this.auth.username,
                                  roles: this.auth.roles[0],
                                  credits: this.auth.credits,
                                  default_country: this.countries.filter(
                                                      country => country.id === this.auth.default_country[0].id)[0],
                                  countries: this.countries
                              };
            }
          );
          this.router.navigate(['/dashboard']);
        } else {
          this.form.setErrors({ invalidLogin: false, blocked: true });
        }
      }, error => {
          this.loading = false;
          if (error.originalError.status === 401) {
            this.form.setErrors({
              invalidLogin: true,
              blocked: false
            });
          }
        });
  }
  // TODO: make dinamic
  private getDefaultCountry(element, index, array) {
    return (element.id === 'AR');
  }

  ngOnInit() {
  }
}
