import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {DataSyncComponentsService} from '../../services/data-sync-components.service';
import {UsersService} from '../services/users.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-usuarios-filters',
  templateUrl: './usuarios-filters.component.html',
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)),
    ])
  ]
})
export class UsuariosFiltersComponent implements OnInit {

  @LocalStorage('session') localSess: any;
  @Input() data: any;
  @Input() id: number;
  @Input() idx: number;
  @Input() countries: any;
  @Input() showLoading: boolean;

  users: any;
  showResult: boolean;
  filtersAvaliable: any;
  filtersSelected: any;
  countrySelected: string;
  categoriesCountries: any;

  constructor(private sync: DataSyncComponentsService, private serviceUsers: UsersService) { }

  // Init Controls
  fSelects = new FormGroup( {
    fCountry: new FormControl(),
    fCategory: new FormControl()
  });


  saveChanges() {

  }

  onSelectCountry(evt) {
    //
    this.countrySelected = this.countries.selected[evt].id;
    this.serviceUsers.getRegularVariablesForCountry(this.countrySelected).subscribe(
      result => {
        this.categoriesCountries = result;
        this.fCategories.reset();
      });
    //
  }

  onSelectCategory(evt) {
    //
    let allFilters;
    let userFilters;
    allFilters = this.categoriesCountries[evt].variables;
    this.serviceUsers.getFiltersForUserCountry(this.id, this.countrySelected).subscribe(
      resultUser => {
        userFilters = resultUser;
        const usersFiltersSelected  = userFilters.filter(item => item['name'] === this.categoriesCountries[evt].name);
        this.filtersAvaliable       = allFilters.filter(item => !usersFiltersSelected[0].variables.some(other => item.name === other.name));
        this.filtersSelected        = usersFiltersSelected[0].variables;
      });
  }


  removeItem(item) {
    this.filtersAvaliable.push(this.filtersSelected[item]);
    this.filtersSelected.splice(item, 1);
  }

  addItem(item) {
    this.filtersSelected.push(this.filtersAvaliable[item]);
    this.filtersAvaliable.splice(item, 1);
  }

  get fCategories () {
    return this.fSelects.get('fCategory');
  }

  ngOnInit() {
  }

}
