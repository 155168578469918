export enum Endpoints {
  root                = 'https://api.data.intrabase.solutions/',
  login               = 'rest/user/login',
  getAllUsers         = 'admin/users',
  getFiltersByCountry = 'rest/country/{id}/variables?group_by_category=true',
  getCountries        = 'rest/country',
  purchase            = 'rest/results/purchase',
  proyectar           = 'rest/projection',
  downloadFile        = 'rest/results/purchased/',
  purchased           = 'rest/results/purchased',
  userAdd             = 'admin/users/add',
  userEdit            = 'admin/users/{id}/update',
  userStatus          = 'admin/users/{id}/{status}',
  addCredits          = 'admin/subscriptions/add',
  getAllCountries     = 'rest/country',
  getCountriesForUser = 'admin/user/{id}/countries',
  getVariablesRegular = 'rest/country/{id}/variables?group_by_category=true',
  getVariablesCustom  = 'rest/country/{id}/variables?group_by_category=true&custom=true',
  getUserCountries    = 'admin/user/{id}/countries',
  setUserCountries    = 'admin/user/{id}/countries',
  getUserfilterbyCountry = 'admin/user/{id}/variables?country_id={country}&group_by_category=true',
  // Custom variables
  getDownloadCustom   = 'rest/results/custom/{country}/{variable}',
  // Map
  tokenMap            = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
  getMap              = 'https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=',
  getMapLayer         = 'img/purchased/',
  getMapAreaData      = 'rest/results/purchased/{id}/subset?dryrun=true',
  getMapAreaFile      = 'rest/results/purchased/{id}/subset',
  getMapPointData     = 'rest/results/purchased/{id}/point?dryrun=true',
  getMapPointFile     = 'rest/results/purchased/{id}/point',
  getMapLayerCustom   = 'img/{country}/{variable}',
  getMapAreaDataCustom  = 'rest/results/custom/{country}/{variable}/subset?dryrun=true',
  getMapAreaFileCustom  = 'rest/results/custom/{country}/{variable}/subset',
  getMapPointDataCustom = 'rest/results/custom/{country}/{variable}/point?dryrun=true',
  getMapPointFileCustom = 'rest/results/custom/{country}/{variable}/point'
}
