import { Component, OnInit } from '@angular/core';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';

@Component({
  selector: 'app-usuarios-master',
  templateUrl: './usuarios-master.component.html'
})
export class UsuariosMasterComponent implements OnInit {

  constructor(private sync: DataSyncComponentsService) {
    this.sync.changeActualQuery({locations: [], filters: []});
  }

  ngOnInit() {
  }

}
