import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../shared/common/endpoints.enum';

@Injectable()
export class MapService extends DataService {

  areaData: any;
  pointFile: any;

  constructor(http: HttpClient) {
    super('', http);
  }

  getPointData(idprojection, resource) {
    const newUrl  = this.replaceUrlAreaId(Endpoints.getMapPointData , idprojection);
    return this.areaData = this.sendPOST(Endpoints.root + newUrl, resource);
  }

  getPointFile(idprojection, resource) {
    const newUrl  = this.replaceUrlAreaId(Endpoints.getMapPointFile , idprojection);
    return this.pointFile = this.getFileByPost(Endpoints.root + newUrl, resource);
  }

  getAreaData(idprojection, resource) {
    const newUrl  = this.replaceUrlAreaId(Endpoints.getMapAreaData , idprojection);
    return this.areaData = this.sendPOST(Endpoints.root + newUrl, resource);
  }

  getAreaFile(idprojection, resource) {
    const newUrl  = this.replaceUrlAreaId(Endpoints.getMapAreaFile , idprojection);
    return this.areaData = this.getFileByPost(Endpoints.root + newUrl, resource);
  }

  // for Custom
  getAreaDataCustom(country, variable, resource) {
    const newUrl  = this.replaceUrlCustom(Endpoints.getMapAreaDataCustom, country, variable);
    return this.areaData = this.sendPOST(Endpoints.root + newUrl, resource);
  }

  getAreaFileCustom(country, variable, resource) {
    const newUrl  = this.replaceUrlCustom(Endpoints.getMapAreaFileCustom, country, variable);
    return this.areaData = this.getFileByPost(Endpoints.root + newUrl, resource);
  }

  getPointDataCustom(country, variable, resource) {
    const newUrl  = this.replaceUrlCustom(Endpoints.getMapPointDataCustom, country, variable);
    return this.areaData = this.sendPOST(Endpoints.root + newUrl, resource);
  }

  getPointFileCustom(country, variable, resource) {
    const newUrl  = this.replaceUrlCustom(Endpoints.getMapPointFileCustom, country, variable);
    return this.areaData = this.getFileByPost(Endpoints.root + newUrl, resource);
  }

  // replace parameters for url, only for status
  private replaceUrlAreaId (url, id) {
    return url.replace('{id}', id);
  }

  private replaceUrlCustom(url, country, variable) {
    return url.replace('{country}', country).replace('{variable}', variable);
  }

}
