import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../downloads/services/purchase.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-custom-filters',
  templateUrl: './custom-filters.component.html'
})
export class CustomFiltersComponent implements OnInit {

  constructor(private servicePurchase: PurchaseService) { }

  customArchivedFile() {
    const id = '120b0947-0574-4156-98e2-7badf41d27c0';
    const total = '10000';
    this.servicePurchase.downloadFile(id).subscribe(
      file => {
        const blobFile = new Blob([file]);
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blobFile);
          const nameArchive = this.getFileName(total);
          link.setAttribute('href', url);
          link.setAttribute('download', nameArchive);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    );
  }

  private getFileName(total) {
    const today = new Date();
    const formatToday = formatDate( today,  'yyyy_MM_dd_hhmm', 'en-US' );
    return 'intrabase_archived_' + formatToday + '_' + total + '.csv';
  }

  ngOnInit() {
  }

}
