import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSyncComponentsService } from '../services/data-sync-components.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private sync: DataSyncComponentsService) {
    localStorage.clear();
    this.sync.changeActualQuery({locations: [], filters: []});
    this.sync.changeFiltersSelected([]);
    this.sync.changeAllQueries([]);
    this.sync.changeCategoriesCmb([]);
    this.sync.changeFilters([]);
    this.sync.changeSession([]);
    this.sync.changeVariablesCmb([]);
    this.sync.changeFiltersCustomSelected([]);
    this.router.navigate(['/login']);
  }

  ngOnInit() {
  }

}
