import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../shared/common/endpoints.enum';
import {DataSyncComponentsService} from './data-sync-components.service';

@Injectable()
export class FiltersService extends DataService {

  filters: any;

  constructor(http: HttpClient) {
    super('', http);
  }

  getFiltersForCountry (idcountry) {

    const url = Endpoints.root + this.replaceUrlParams( Endpoints.getFiltersByCountry, idcountry );
    this.sendGET(url).subscribe( filters => {
      this.filters = filters;
    });
  }
}
