<!-- Actual Query -->
<div class="row">
  <div class="col-md-12">
    <div class="card padding-20">
      <h4 class="card-title no-mrg-btm"><i class="ti-layers-alt"></i> Filtros y Ubicaciones</h4>
      <div *ngIf="(actualQuery.locations.length==0)" class="row mrg-top-30"><div class="col-sm-12 text-center"><img src="../../../assets/images/loading_small.gif"> Cargando datos...</div></div>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <ul [ngClass]="(filtersCustomSelected.length>0) ? 'd-none' : 'd-block'" class="list-members">
            <li *ngFor="let option of actualQuery.locations; index as i" style="float: none;">
              <!-- TODO: add pipe location pipe -->
              <span class="label label-info label-actualquery zoomIn">{{ option.label }}
                <label class="text-dark pointer"
                   *ngIf="(option.id != 'COUNTRY')"
                   placement="right" ngbTooltip="Quitar"
                   (click)="removeLocation(i)"><i class="ti-trash"></i></label></span>
            </li><br>
          </ul>
          <br>
          <ul [ngClass]="(filtersCustomSelected.length>0) ? 'd-none' : 'd-block'" class="list-members">
            <li *ngFor="let filter of actualQuery.filters; index as e">
              <span class="label label-primary label-actualquery zoomIn">{{ filter.value | filtersName: filter.key }}
              <label class="pointer"
                 placement="right" ngbTooltip="Quitar"
                 (click)="removeFilter(e)"><i class="ti-trash text-primary"></i></label>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 text-center">
          <app-proyectar></app-proyectar>
        </div>
      </div>
    </div>
  </div>
</div>
