import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';

@Component({
  selector: 'app-actual-query',
  templateUrl: './actual-query.component.html'
})
export class ActualQueryComponent implements OnInit {

  actualQuery: any;
  queryMaster: any[];
  filtersCustomSelected: any;
  chartData: any;
  constructor(private sync: DataSyncComponentsService) { }

  removeFilter(idx) {
    this.actualQuery.filters.splice(idx,1);
  }

  removeLocation (idx) {
    // delete from charts
    const idxChart = this.chartData.findIndex(o => this.actualQuery.locations[idx].label === o.label);
    this.chartData.splice(idxChart, 1);
    this.sync.changeChartData(this.chartData);
    // delete from locations
    this.actualQuery.locations.splice(idx, 1);
  }

  ngOnInit() {
    this.sync.currentActualQuery.subscribe(query => this.actualQuery = query );
    this.sync.currentQueryMaster.subscribe( master => this.queryMaster = master );
    this.sync.currentFiltersCustomSelected.subscribe( filtersCustom => this.filtersCustomSelected = filtersCustom);
    this.sync.currentChartData.subscribe( data => this.chartData = data);
  }
}
