import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usersLista'
})
export class UsersListaPipe implements PipeTransform {

  private retValue: string;

  transform(value: string): string {
    // parse
    switch (value) {
      case 'ROLE_ADMIN':
        this.retValue = 'Administrador';
        break;
      case 'ROLE_USER':
        this.retValue = 'Usuario';
        break;
      default:
        this.retValue = '';
        break;
    }
    return this.retValue;
  }

}
