import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtersName'
})

export class FiltersNamePipe implements PipeTransform {

  private retValue: string;

  transform(value: string, key: string): string {
    // parse
    const oName = key.split('_');
    this.retValue = oName[0] + '/' + oName[1] + ': ' + value;
    return this.retValue;
  }

}
