import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSyncComponentsService {

  private filtersData   = new BehaviorSubject(null);
  private locationsData = new BehaviorSubject('');
  private queryMaster   = new BehaviorSubject([]);
  private actualQuery   = new BehaviorSubject({locations: [], filters: [], customFilters: [], totalData: []});
  private allQueries    = new BehaviorSubject([]);
  private users         = new BehaviorSubject(null);
  private session       = new BehaviorSubject({token: null, user: null});
  private modals        = new BehaviorSubject(null);
  private categoriesCombo = new BehaviorSubject({all: [], custom: []});
  private variablesCombo  = new BehaviorSubject([]);
  private filtersSelected = new BehaviorSubject([]);
  private filtersCustomSelected = new BehaviorSubject([]);
  private changedCountry = new BehaviorSubject(false);
  private chartData     = new BehaviorSubject([]);
  currentChartData      = this.chartData.asObservable();
  currentFilters        = this.filtersData.asObservable();
  currentLocations      = this.locationsData.asObservable();
  currentQueryMaster    = this.queryMaster.asObservable();
  currentActualQuery    = this.actualQuery.asObservable();
  currentAllQueries     = this.allQueries.asObservable();
  currentUsers          = this.users.asObservable();
  currentSession        = this.session.asObservable();
  currentModals         = this.modals.asObservable();
  currentCategoriesCmb  = this.categoriesCombo.asObservable();
  currentVariablesCmb   = this.variablesCombo.asObservable();
  currentFiltersSelected = this.filtersSelected.asObservable();
  currentChangedCountry  = this.changedCountry.asObservable();
  currentFiltersCustomSelected = this.filtersCustomSelected.asObservable();

  constructor() { }

  changeChartData(chartData: any) {
    this.chartData.next(chartData);
  }

  changeCountry (flag: boolean) {
    this.changedCountry.next(flag);
  }

  changeFilters (filters: any) {
    if (filters !== null) {
      filters = filters.slice(1);
      this.filtersData.next(filters);
    }
  }

  changeActualQuery (query: any) {
    this.actualQuery.next(query);
  }

  changeLocations (locations: any) {
    // Parse data of locations
    this.locationsData.next(locations);
  }

  changeQueryMaster (query: any) {
    this.queryMaster.next(query);
  }

  changeAllQueries (queries: any) {
    this.allQueries.next(queries);
  }

  changeUsers (users: any) {
    this.users.next(users);
  }

  changeSession (session: any) {
    this.session.next(session);
  }
  changeModals (modals: any) {
    this.modals.next(modals);
  }

  changeCategoriesCmb (categoriesCombo: any) {
    this.categoriesCombo.next(categoriesCombo);
  }

  changeVariablesCmb (variablesCombo: any) {
    this.variablesCombo.next(variablesCombo);
  }

  changeFiltersSelected (filtersSelected: any) {
    this.filtersSelected.next(filtersSelected);
  }

  changeFiltersCustomSelected(filtersCustomSelected: any) {
    this.filtersCustomSelected.next(filtersCustomSelected);
  }

}
