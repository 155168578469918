import { Component, OnInit } from '@angular/core';
import { DataSyncComponentsService } from '../../services/data-sync-components.service';
import { LocationsService } from '../../services/locations.service';
import { PurchaseService } from '../../downloads/services/purchase.service';
import { Endpoints } from '../../shared/common/endpoints.enum';
import { formatDate } from '@angular/common';
import {LocalStorage} from 'ngx-webstorage';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-proyectar',
  templateUrl: './proyectar.component.html'
})
export class ProyectarComponent implements OnInit {

  actualQuery: any;
  query: any[];
  dataQueryLocations = '';
  dataQueryFilters = '';
  dataQuery = '';
  resultQuery: any;
  dashboardResult: any = null;
  loading = true;
  allQueries: any;
  credits: number;
  purchase: any;
  msgLoading: string;
  showProyeccion = false;
  showArchivo = false;
  showConfirm = false;
  downloadUrl: string;
  purcahseID: string;
  purchaseTotal: number;
  file: any;
  filtersCustomSelected: any;

  chartOptions;
  chartData;

  @LocalStorage('session') localSess: any;

  constructor(private sync: DataSyncComponentsService, private service: LocationsService, private servicePurchase: PurchaseService) {}

  // Init Controls
  fTypeFile = new FormGroup( {
    fTypeFileField: new FormControl()
  });

  // confirm download file
  confirmDownload () {
    this.showConfirm = true;
  }
  negateConfirm() {
    this.showConfirm = false;
  }

  // download file
  downloadFile () {
    this.servicePurchase.downloadFile(this.purcahseID).subscribe(
      file => {
        const blobFile = new Blob([file]);
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blobFile);
          const nameAchive = this.getFileName(this.purchaseTotal);
          link.setAttribute('href', url);
          link.setAttribute('download', nameAchive);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    );
  }

  // generate download
  generateDownload() {
    this.showConfirm = false;
    this.loading = true;
    this.showProyeccion = false;
    this.msgLoading = 'Generando Archivo...';
    const queryParsed = this.getDataQueryParsed(this.fileType.value);
    const queryParams: string = queryParsed;
    this.servicePurchase.getIdPurchase(queryParams).subscribe(
      result => {
        this.purchase = result;
        this.purcahseID = this.purchase.id;
        this.purchaseTotal = this.purchase.results;
        this.downloadUrl = Endpoints.root + Endpoints.downloadFile + this.purchase.id;
        // Update credits in session
        this.updateCreditsAfterPurchase(this.purchase.credits.remaining);
        this.showArchivo = true;
        this.loading = false;
      }
    );
  }

  // send query to service
  getResults() {
    this.showConfirm = false;
    this.showArchivo = false;
    this.loading = true;
    this.msgLoading = 'Generando Proyeccion...';
    // Parse filters
    const queryParsed = this.getDataQueryParsed();
    // Call to service
    let queryParams: string;
    queryParams = queryParsed;

    this.service.getReultsQuery(queryParams).subscribe(
      result => {
        this.resultQuery = result;
        this.dashboardResult = {'total_ubicaciones': this.resultQuery.total, 'total_hogares': this.resultQuery.section_data.total_hogares};
        // Add to executed queries
        this.addQueryToQueries(queryParams, this.resultQuery.total, this.actualQuery);
        this.dataQuery = '';
        this.loading = false;
        this.showProyeccion = true;
      }
    );
  }

  // open recents querys
  openRecentQuerys() {
    this.loading = false;
    this.msgLoading = 'Generando Proyeccion...';

    this.chartOptions = {
      chart: {
        type: 'discreteBarChart',
        height: 200,
        width: 700,
        tooltips: false,
        margin : {
          top: 20,
          right: 20,
          bottom: 50,
          left: 55
        },
        x: function(d) { return d.label; },
        y: function(d) { return  d.value; },
        valueFormat: function(d) {
          return d3.format('.1')(d);
        },
        showValues: true,
        duration: 500,
        xAxis: {
          axisLabel: 'Proyecciones'
        },
        yAxis: {
          axisLabel: ''
        }
      }
    };
    let tmpDataChart = '';
    this.allQueries.forEach( (option: any, key: any) => {
      tmpDataChart += '{ "label": "PR_' + key + '", "value": ' + option.total + '},';
    });
    tmpDataChart = '[' + tmpDataChart.slice(0, -1) + ']';
    this.chartData = [{ values: JSON.parse(tmpDataChart) }];
  }

  private getFileName(total) {
    const today = new Date();
    const formatToday = formatDate( today,  'yyyy_MM_dd_hhmm', 'en-US' );
    return 'intrabase_' + formatToday + '_' + total + '.csv';
  }

  private getDataQueryCustomParsed (fileFormat?) {
    let finalQuery: string;
    this.dataQueryFilters = '';
    this.dataQueryLocations = '';
    // Re order locations
    if (this.actualQuery.locations !== null) {
      this.actualQuery.locations.forEach(filter => {
        let finalValue = '';
        if (filter.id !== 'COUNTRY') {
          filter.data.forEach( (option, idx) => {
            finalValue += '"location_l' + (idx + 2) + '": "' + option['L' + (idx + 2)] + '",';
          });
        }
        if (finalValue.length > 0) {
          this.dataQueryLocations += '{' + finalValue.slice(0, -1) + '},';
        }
      });
    }
    if (this.filtersCustomSelected.length>0) {
      const filterName = this.actualQuery.customFilters[0].id;
      this.actualQuery.customFilters[0].slices.forEach(filter => {
        const finalValue = {key: filterName, value: filter.obj};
        this.dataQueryFilters += JSON.stringify(finalValue) + ',';
      });
    }
    finalQuery = '{ "country_id": "' + this.actualQuery.locations[0].value + '",';
    if (fileFormat) {
      finalQuery += ' "format" : "' + fileFormat + '", ';
    }
    finalQuery += ' "location_filters" : [' + this.dataQueryLocations.slice(0, -1) + '], ' +
      ' "variable_filters": [' + this.dataQueryFilters.slice(0, -1) + '] }';

    return finalQuery;
  }

  // Parse data for final query
  private getDataQueryParsed(fileFormat?) {
    let finalQuery: string;
    this.dataQueryFilters = '';
    this.dataQueryLocations = '';
    // Re order locations
    if (this.actualQuery.locations !== null) {
      this.actualQuery.locations.forEach(filter => {
        let finalValue = '';
        if (filter.id !== 'COUNTRY') {
          filter.data.forEach( (option, idx) => {
            finalValue += '"location_l' + (idx + 2) + '": "' + option['L' + (idx + 2)] + '",';
          });
        }
        if (finalValue.length > 0) {
          this.dataQueryLocations += '{' + finalValue.slice(0, -1) + '},';
        }
      });
    }
    if (this.actualQuery.filters !== null) {
      this.actualQuery.filters.forEach(filter => {
        const finalValue = {key: filter.id, value: filter.value};
        this.dataQueryFilters += JSON.stringify(finalValue) + ',';
      });
    }
    finalQuery = '{ "country_id": "' + this.actualQuery.locations[0].value + '",';
      if (fileFormat) {
        finalQuery += ' "format" : "' + fileFormat + '", ';
      }
    finalQuery += ' "location_filters" : [' + this.dataQueryLocations.slice(0, -1) + '], ' +
      ' "variable_filters": [' + this.dataQueryFilters.slice(0, -1) + '] }';

    return finalQuery;
  }

  // add recent query for reports
  private addQueryToQueries (query, result, actualQuery) {
    const actualQueries = this.allQueries;
    actualQueries.push({ dataView: { locations: actualQuery.locations, filters: actualQuery.filters },
                          query: query,
                          total: result});
    this.sync.changeAllQueries(actualQueries);
  }

  private updateCreditsAfterPurchase(credits) {
    this.localSess['credits'] = credits;
  }

  ngOnInit () {
    this.sync.currentQueryMaster.subscribe(query => this.query = query );
    this.sync.currentActualQuery.subscribe(query => this.actualQuery = query );
    this.sync.currentAllQueries.subscribe( queries => this.allQueries = queries );
    this.sync.currentFiltersCustomSelected.subscribe( filtersCustom => this.filtersCustomSelected = filtersCustom);
  }

  get fileType() {
    return this.fTypeFile.get('fTypeFileField');
  }

}
