import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'shared/common/endpoints.enum';

@Injectable()
export class UsersService extends DataService {

  users: any;
  newUser: any;
  editUser: any;
  addCredits: any;
  userStatus: any;
  allCountries: any;
  variablesRegular: any;
  variablesCustom: any;

  constructor(http: HttpClient) {
    super('', http);
  }

  getFiltersForUserCountry(iduser, country) {
    const newUrl  = this.replaceUserCountry(Endpoints.getUserfilterbyCountry, iduser, country);
    return this.users = this.sendGET(Endpoints.root + newUrl);
  }

  setCountriesForUser(iduser, resource) {
    const newUrl  = this.replaceUrlParams(Endpoints.setUserCountries, iduser);
    return this.users = this.sendPOST(Endpoints.root + newUrl, resource);
  }

  // get countries for an user
  getCounriesForUser(iduser) {
    const newUrl  = this.replaceUrlParams(Endpoints.getCountriesForUser, iduser);
    return this.users = this.sendGET(Endpoints.root + newUrl);
  }

  // return all userseee avaliable
  getAllUsers () {
    return this.users = this.sendGET( Endpoints.root + Endpoints.getAllUsers );
  }

  // add new user
  setNewUser (resource) {
    return this.newUser = this.sendPOST( Endpoints.root + Endpoints.userAdd, resource);
  }

  // edit user, password
  editUserPass (resource, id) {
    const newUrl = this.replaceUrlParams(Endpoints.userEdit, id);
    return this.editUser = this.sendPOST( Endpoints.root + newUrl, resource);
  }

  // add credits
  addCreditsToUser (resource) {
    return this.addCredits = this.sendPOST(Endpoints.root + Endpoints.addCredits, resource);
  }

  // set user status
  setUserStatus(id, status) {
    const newUrl = this.replaceUrlStatus(Endpoints.userStatus, id, status);
    return this.userStatus = this.sendPOST(Endpoints.root + newUrl);
  }

  // get all countries
  getAllCountries() {
    return this.allCountries = this.sendGET( Endpoints.root + Endpoints.getAllCountries);
  }

  // get regular variables for country
  getRegularVariablesForCountry(country) {
    const newUrl  = this.replaceUrlParams(Endpoints.getVariablesRegular, country);
    return this.variablesRegular = this.sendGET(Endpoints.root + newUrl);
  }

  // get regular variables for country
  getCustomVariablesForCountry(country) {
    const newUrl  = this.replaceUrlParams(Endpoints.getVariablesCustom, country);
    return this.variablesRegular = this.sendGET(Endpoints.root + newUrl);
  }

  // replace parameters for url, only for status
  private replaceUrlStatus (url, id, status) {
    return url.replace('{id}', id).replace('{status}', status);
  }
  private replaceUserCountry (url, id, country) {
    return url.replace('{id}', id).replace('{country}', country);
  }
}
