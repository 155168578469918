import { Component, OnInit } from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {LocationsService} from '../../services/locations.service';
import {DataSyncComponentsService} from '../../services/data-sync-components.service';

@Component({
  selector: 'app-query-master',
  templateUrl: './query-master.component.html'
})

export class QueryMasterComponent implements OnInit {

  @LocalStorage('session') localSess: any;
  selectsLocations: any;
  locations: any;
  categoriesCombo: any;
  actualQuery: any;
  filtersCustomSelected: any;

  constructor(private service: LocationsService, private sync: DataSyncComponentsService) {
    this.sync.changeActualQuery({locations: [], filters: [], customFilters: [], totalData: []});
    this.sync.changeFiltersSelected([]);
    // load country
    const dataQuery = { 'country_id': this.localSess.default_country.id };

    this.service.getSelectsLocation(dataQuery).subscribe(
      locations => {
        this.selectsLocations = locations;
        //
        const arrLocations = this.actualQuery;
        arrLocations.locations.push({ id: 'COUNTRY',
                                      label: this.localSess.default_country.name,
                                      value: this.localSess.default_country.id });
        // set total of data
        arrLocations.totalData.push({ totalLocations: this.selectsLocations.total });
        // Load categories combo
        this.sync.changeCategoriesCmb(this.filterCategories(this.selectsLocations.data_category_filters));
        // Sync locations
        this.sync.changeLocations(arrLocations);
      }
    );
  }
  private filterCategories (oFilters) {
    let custom = {};
    if (oFilters.some(e => e.name === 'CUSTOM')) {
      custom = oFilters.filter(e => e.name === 'CUSTOM')[0];
    }
    return {'all' : oFilters.filter(e => e.name !== 'CUSTOM'), 'custom' : custom};

  }

  ngOnInit() {
    this.sync.currentLocations.subscribe( locations => this.locations = locations );
    this.sync.currentCategoriesCmb.subscribe( categoriesCombo => this.categoriesCombo = categoriesCombo );
    this.sync.currentActualQuery.subscribe( query => this.actualQuery = query );
    this.sync.currentFiltersCustomSelected.subscribe( filtersCustom => this.filtersCustomSelected = filtersCustom);
  }

}
