import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CategoryName'
})

export class CategoriesNamePipe implements PipeTransform {

  private retValue: string;

  transform(value: string): string {
    // parse
    const oData = value.split('_');
    this.retValue = oData[0] + ' / ' + oData[1];
    return this.retValue;
  }

}
